/* vite相关 */
import dotenv from 'dotenv';
export interface ViteEnv {
    VITE_PORT: number
    VITE_OPEN: boolean
    VITE_BASE_PATH: string
    VITE_OUT_DIR: string
    VITE_AXIOS_BASE_URL: string
    VITE_WS_URL: string
    VITE_PROXY_URL: string
    VITE_API_PATH: string
    VITE_MOCK_PATH: string
    VITE_DEVELOP_HOST: string
    /**
     * Logs level config
     * 0 - only errors production
     * 1 - info, error, requests
     * 2 - full logs
     *
     * @type {(0 | 1 | 2)}
     */
    VITE_LOG_LEVEL: '0' | '1' | '2'
}

/**
 * Проверяет соответствие текущего значения .env.mode со значением второго параметра в виде строки или массива строк
 * @param {string} mode - значение .env.mode.
 * @param {string | string[]} checked_mode - вариант(-ы) .env.mode.
 * @example
 * // returns true
 * isSpecifiedEnvMode('development', 'development');
 * @example
 * // returns false
 * isSpecifiedEnvMode('stage', ['production', 'development']);
 */
export function isSpecifiedEnvMode(mode: string | undefined, checked_mode: string | string[]): boolean {
  if (typeof mode === 'undefined') return false;
  return typeof checked_mode === 'string' ? mode === checked_mode : checked_mode.some((item: string) => item === mode);
}

/**
 * Read all environment variable configuration files to process.env
 */
export function loadEnv(mode: string): ViteEnv {
  const ret: any = {};
  const envList = [`.env.${mode}.local`, `.env.${mode}`, '.env.local', '.env'];

  envList.forEach((e) => {
    dotenv.config({path: e});
  });
  for (const envName of Object.keys(process.env)) {
    let realName = (process.env as any)[envName].replace(/\\n/g, '\n');
    realName = realName === 'true' ? true : realName === 'false' ? false : realName;
    if (envName === 'VITE_PORT') realName = Number(realName);
    if (envName === 'VITE_OPEN') realName = Boolean(realName);
    if (envName === 'VITE_PROXY') {
      try {
        realName = JSON.parse(realName);
      } catch (error) {
        realName = '';
      }
    }
    ret[envName] = realName;
    if (typeof realName === 'string') {
      process.env[envName] = realName;
    } else if (typeof realName === 'object') {
      process.env[envName] = JSON.stringify(realName);
    }
  }
  return ret;
}
