export default {
  labels: {
    title: 'Операция',
    amount: 'Сумма, ₽',
    balance: 'Баланс, ₽',
    date: 'Дата операции',
    fillBalance: 'Сумма, ₽',
  },
  fillBalance: 'Пополнение баланса',
  placeholder: {
    searchBills: 'Поиск операции по названию',
    fillBalance: 'Введите сумму',
  },
  balanceFilledFor: 'Пополнение баланса',
  buyLicense: 'Подключение подписки "{title}" до {date}',
};
