export default {
  wbwarehouses: {
    noAPI: 'Для отображения списка складов добавьте в свойствах продавцов токен API для Аналитики',
    noAPIButton: 'Настроить продавцов',
    placeholders: {
      findWarehouse: 'Поиск склада по названию',
      allWarehouses: 'Все склады',
      activeWarehouses: 'Используемые склады',
      disabledWarehouses: 'Неиспользуемые склады',
    },
    title: {
      options: 'Свойства склада',
    },
    address: 'Адрес',
    active: 'Состояние',
    useActive: 'Используется для планирования поставок',
  },
  supply: {
    productName: 'Наименование товара',
    nmId: 'Артикул WB',
    supplierId: 'Артикул продавца',
    slSpeed: 'Темп продаж в день',
    qty: 'В наличии, шт',
    qtyPredict: 'Прогноз наличия, дн',
    inWayToWh: 'В пути, шт',
    noAPI: `Для отображения таблицы планирования поставок настройте параметры и включите хотя бы один склад
    на странице "Склады Wildberries".`,
    noAPIButton: 'Настроить склады',
  },
  whType: 'Тип склада',
  whName: 'Название склада',
  expSupply: 'Планируемый срок поставки, дн',
  whTurns: 'Планируемая оборачиваемость, дн',
  exportXLS: 'Экспорт в XLS',
  exportCSV: 'Экспорт в CSV',
  export: 'Экспорт',
  total: 'Всего: ',
};
