import {App} from 'vue';
import {WysiwygComponent} from './wysiwyg';
import {ModalFormComponent} from './modal-form';
import {ModalWizardComponent} from './modal-wizard';
import {CustomSelectComponent} from './select-create';

const globalComponents = [
  WysiwygComponent,
  ModalFormComponent,
  ModalWizardComponent,
  CustomSelectComponent,
];

export function components(app: App<Element>) {
  for (const comp of globalComponents) {
    app.component(comp.default.name, comp.default);
  }
}
