<template>
  <div ref="container">
    <div class="toolbar">
      <div v-for="group in controls" :key="group.name" class="toolbar-group">
        <div class="toolbar-title">
          {{ group.name }}
        </div>
        <div v-for="(control, index) in group.controls" :key="index" class="toolbar-control">
          <el-tooltip append-to="body" teleported :disabled="!control.tooltip" :content="control.tooltip" placement="top">
            <el-tag class="mr1 cursor-pointer" size="small" type="info" effect="dark" round @click="() => addTemplate(control)">
              <template v-if="iconWithName || !Boolean(control.icon)">
                {{ control.value }}
              </template>

              <!-- code to add icon to the tag-->
              <template v-if="iconWithName"> &nbsp; </template>
              <template v-if="Boolean(control.icon)">
                <font-awesome-icon :icon="control.icon" />
              </template>
            </el-tag>
          </el-tooltip>
        </div>
      </div>
    </div>
    <div class="control">
      <slot />
      <slot name="input" />
    </div>
  </div>
</template>

<script lang="ts">
import { ElInput } from 'element-plus';
import Icon from '/@/components/icon/index.vue';
import { watch, defineProps, ref, PropType, defineEmits, defineComponent, useSlots, SetupContext, Ref } from 'vue';
import { logger } from '/@/utils/logger';
import { WysiwygTool, WysiwygToolGroup } from './type';
import { transformValue } from './hepler';

export default defineComponent({
  name: 'ElWysiwyg',
  components: {
    Icon,
  },
  props: {
    controls: {
      type: Array as PropType<WysiwygToolGroup[]>,
      required: true,
    },
    iconWithName: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['selected'],
  setup() {
    const currentValue = ref<unknown>();
    const slots = useSlots();
    const inputRef = ref<HTMLTextAreaElement | HTMLInputElement>();
    const container = ref<HTMLElement>();

    return {
      container,
      currentValue,
      inputRef,
      slots,
      ElInput,
    };
  },
  beforeUpdate() {
    this.setRef();
  },
  mounted() {
    this.setRef();
  },
  methods: {
    setRef() {
      const input = (this.container as HTMLElement).querySelector('.control input') as HTMLInputElement;
      const textarea = (this.container as HTMLElement).querySelector('.control textarea') as HTMLTextAreaElement;
      this.inputRef = input || textarea;
    },
    addTemplate(tool: WysiwygTool) {
      if (!this.inputRef) {
        return false;
      }
      const control: HTMLTextAreaElement | HTMLInputElement = this.inputRef;

      if (!(control instanceof HTMLTextAreaElement || control instanceof HTMLInputElement)) {
        return false;
      }

      const { value, pos } = transformValue(control, tool);
      this.applyTemplate(control, value, pos);
    },

    applyTemplate(control: HTMLTextAreaElement | HTMLInputElement, value: string, pos: number) {
      this.$emit('selected', value);
      setTimeout(() => {
        control.focus();
        control.setSelectionRange(pos, pos);
      }, 1);
    },
  },
});
</script>

<style scoped lang="scss">
.toolbar {
  --el-border-radius: 0 0 var(--el-border-radius-base) var(--el-border-radius-base);
  --el-border-radius-reverse: var(--el-border-radius-base) var(--el-border-radius-base) 0 0;
}
.toolbar-title {
  display: inline;
  margin-left: 8px;
  font-size: 12px;
}

.toolbar-control {
  display: inline;
  margin-left: 8px;
  line-height: 30px;
}

.toolbar-group {
  display: inline;
  align-items: center;
}

.toolbar-group + .toolbar-group {
  &::before {
    display: inline-block;
    margin: 3px 0;
    content: '|';
  }
}

.control {
  margin-top: 0;
}

.control:deep(.el-textarea),
.control:deep(.el-input) {
  --el-input-border-radius: 0 0 var(--el-border-radius-base) var(--el-border-radius-base);
}

.toolbar {
  background-color: var(--el-fill-color-light);
  border: var(--el-border);
  border-color: var(--el-border-color);
  padding: 4px;
  font-size: var(--el-font-size-base);
  border-radius: var(--el-border-radius-base) var(--el-border-radius-base) 0 0;
}
</style>
