export default {
  title: '{action} задания',
  countLines: 'Всего { count } строк',
  noResults: 'Нет настроенных заданий',
  noResultsButton: 'Создать задание',
  actions: {
    add: 'Создать задание',
    edit: 'Редактрование',
    delete: 'Удалить',
  },
  placeholders: {
    action: 'Выберите тип задания',
    recipient: 'Введите электронную почту',
    filterName: 'Поиск задания по названию',
  },
  label: {
    action: 'Задание',
    cronRule: 'Расписание',
    startTime: 'Время запуска',
    everyDay: 'Каждый день',
    selectWeekDay: 'В выбранный день недели',
    recipient: 'Получатель',
    state: 'Состояние',
    from: 'C',
    to: 'До',
    to2: 'По',
    every: 'Каждые',
    in: 'in',
    repeat: 'Повторять',
    time: 'Время',
    timeStep: 'Каждыне',
    minute: 'Минуты',
    hour: 'Часы',
    dayOfWeek: 'Дни недели',
    dayOfMonth: 'Числа Месяца',
    repeatTask: 'Повтор запуска',
  },
  button: {
    stop: 'Остановить',
    start: 'Запустить сейчас',
  },
  'feedbacks:new': 'Отчет по новым отзывам Wildberries',
  'feedbacks:summary': 'Отчет по обработанным отзывам Wildberries',
  'feedbacks:lastLowRate': 'Уведомления о негативных отзывах Wildberries',
  'autobot:disassembleFeedbacks': 'Запуск Автобота Wildberries',
  'autobot:disassembleOzonFeedbacks': 'Запуск Автобота OZON',
  dialog: {
    delete: {
      messageOne: 'Удалить задание?',
      messageMany: 'Удалить выбранные задания?',
      titleOne: 'Удаление задания',
      titleMany: 'Удаление заданий',
    },
  },
  modal: {
    title: {
      create: 'Создание назначенного задания',
      edit: 'Редактирование назначенного задания',
    },
  },
};
