export default {
  modals: {
    createDeal: {
      title: 'Создать сделку',
    },
    serviceType: {
      title: {
        list: 'Выберите тип услуги',
        create: 'Создать тип услуги',
        edit: 'Редактировать тип услуги',
        delete: 'Удалить тип услуги',
      },
      body: {
        delete: 'Подтвердите удаление услуги',
      },
    },
    expectedPaymentType: {
      title: {
        list: 'Выберите тип оплаты',
        create: 'Создать тип оплаты',
        edit: 'Редактировать тип оплаты',
        delete: 'Удалить тип оплаты',
      },
      body: {
        delete: 'Подтвердите удаление способа оплаты',
      },
    },
    requestType: {
      title: {
        list: 'Выберите тип обращения',
        create: 'Создать тип обращения',
        edit: 'Редактировать тип обращения',
        delete: 'Удалить тип обращения',
      },
      body: {
        delete: 'Подтвердите удаление обращения',
      },
    },
    source: {
      title: {
        list: 'Выберите источник',
        create: 'Создать источник',
        edit: 'Редактировать источник',
        delete: 'Удалить источник',
      },
      body: {
        delete: 'Подтвердите удаление источника',
      },
    },
    customField: {
      title: {
        create: 'Добавление нового поля',
        edit: 'Редактирование поля',
        delete: 'Удаление поля',
      },
      alerts: {
        newField: 'Новое поле будет доступно всем пользователям',
        existField: 'Если добавленное поле уже содержит данные, изменить тип данных нельзя.',
        delete: 'При удалении поля будут удалены все веденные в это поле данные.',
      },
      body: {
        delete: 'Удалить поле?',
      },
      labels: {
        name: 'Название поля',
        placeholder: 'Подсказка',
        type: 'Тип данных',
        required: 'Обязательно к заполнению',
        visible: 'Скрыть поле',
      },
      placeholders: {
        name: 'Введите название поля',
        placeholder: 'Введите текст подсказки, когда поле не заполнено',
        type: 'Выберите тип данных',
      },
      buttons: {
        cancel: 'Отмена',
        delete: 'Удалить',
        add: 'Добавить',
        save: 'Сохранить',
      },
    },
  },
  theme: 'Тема',
  contact: 'Контакт',
  expectedRevenue: 'Ожидаемый доход',
  company: 'Компания',
  salesPerson: 'Ответственный',
  workflow: 'Название стадии',
  newNote: 'Новое примечание',
  priority: 'Приоритет',
  requestType: 'Обращение',
  source: 'Источник вакансии',
  expectedPaymentType: 'Тип оплаты',
  serviceType: 'Тип услуги',
  expectedClosing: 'Планируемая дата завершения',
  shipmentDate: 'Планируемая дата поставки',
  internalNotes: 'Заметки',
  dateBirth: 'Дата рождения',
  firstName: 'Имя',
  lastName: 'Фамилия',
  middleName: 'Отчество',
  jobPosition: 'Должность',
  phone: 'Телефон',
  email: 'Email',
  personalEmail: 'Личный Email',
  emailLogin: 'Email (Логин для входа)',
  contactName: 'Контакт',
  createdBy: 'Кем создано',
  relationshipType: 'Тип отношений',
  customer: 'Клиент',
  name: 'Имя',
  country: 'Страна',
  services: 'Сервисы',
  channel: 'Канал',
  supplier: 'Поставщик',
  opportunity: 'Сделка',
  status: 'Статус',
  employmentType: 'Вид занятости',
  department: 'Подразделение',
  jobType: 'Тип занятости',
  nationality: 'Гражданство',
  city: 'Город / Населенный пункт',
  street: 'Улица, дом, квартира',
  state: 'Регион',
  zip: 'Почтовый индекс',
  gender: 'Пол',
  passport: 'Паспорт',
  marital: 'Семейное положение',
  workEmail: 'Рабочий Email',
  mobile: 'Личный телефон',
  inn: 'ИНН',
  manager: 'Руководитель',
  date: 'Дата',
  weeklyScheduler: 'График',
  payrollStructureType: 'Договор',
  desiredPosition: 'Желаемая должность',
  desiredSalary: 'Желаемый оклад',
  proposedSalary: 'Предложенный оклад',
  nextAction: 'Дата принятия решения',
  scheduledPay: 'Форма оплаты',
  statusEmployment: 'Статус набора сотрудников',
  organization: 'Название организации',
  website: 'Сайт',
  workflowProcess: 'Рабочий процесс',
  workflowCount: 'Количество стадий',
  placeholders: {
    salary: 'Введите оклад',
    desiredPosition: 'Выберите желаемую должность',
    desiredSalary: 'Введите желаемый оклад',
    proposedSalary: 'Введите предложенный оклад',
    nextAction: 'Выберите дату принятия решения',
    info: 'Введите информацию',
    expectedRevenue: 'Введите ожидаемый доход',
    mobile: 'Введите личный телефон',
    weeklyScheduler: 'Выберите график',
    contact: 'Выберите или создайте новый контакт',
    passport: 'Введите паспортные данные',
    company: 'Выберите компанию',
    scheduledPay: 'Выберите форму оплаты',
    salesPerson: 'Выберите ответственного',
    createdBy: 'Выберите автора',
    workflow: 'Выберите стадию',
    inn: 'Введите ИНН',
    priority: 'Выберите приоритет',
    requestType: 'Выберите обращение',
    services: 'Выберите тип отношений',
    country: 'Выберите страну',
    source: 'Введите источник',
    personalEmail: 'Введите личный Email',
    payrollStructureType: 'Выберите договор',
    expectedPaymentType: 'Выберите тип оплаты',
    serviceType: 'Выберите тип услуги',
    expectedClosing: 'Выберите дату завершения сделки',
    shipmentDate: 'Выберите дату поставки',
    internalNotes: 'Введите текст примечания до 2500 символов',
    industry: 'Введите вид деятельности',
    notes: 'Введите текст до 500 символов',
    firstName: 'Введите имя',
    lastName: 'Введите фамилию',
    middleName: 'Введите отчество',
    jobPosition: 'Выберите должность',
    phone: 'Введите номер телефона в любом формате',
    email: 'Введите адрес электронной почты',
    name: 'Введите название',
    dateBirth: 'Введите дату рождения',
    employmentType: 'Введите тип занятости',
    department: 'Выберите подразделение',
    jobType: 'Выберите тип занятости',
    city: 'Введите город / населенный пункт',
    street: 'Введите улицу, дом, квартиру',
    date: 'Выберите дату',
    state: 'Введите регион',
    zip: 'Введите индекс',
    workEmail: 'Введите рабочий Email',
    gender: 'Выберите пол',
    marital: 'Введите семейное положение',
    status: 'Выберите статус',
    manager: 'Выберите руководителя',
    parentDepartment: 'Выберите родительское подразделение',
    departmentManager: 'Выберите руководителя подразделения',
  },
  table: {
    amount: 'Сумма',
    fullName: 'Полное имя',
    dateBirth: 'Дата рождения',
    phone: 'Телефон',
    editedBy: 'Дата изменения',
    createdBy: 'Дата создания',
    country: 'Страна',
    email: 'Email',
    priority: 'Приоритет',
    date: 'Дата',
    status: 'Статус',
    employmentType: 'Тип занятости',
    employee: 'Сотрудник',
    manager: 'Руководитель',
    salesPerson: 'Ответственный',
    jobPosition: 'Должность',
    company: 'Компания',
    city: 'Город',
    expectedRevenue: 'Ожидаемый доход',
    payrollStructureType: 'Договор',
    scheduledPay: 'Форма оплаты',
    expectedClosing: 'Планируемая дата завершения',
    salary: 'Оклад',
    info: 'Информация',
    workflow: 'Стадия',
    schedule: 'График',
    tags: 'Теги',
    source: 'Источник',
    changeDate: 'Дата изменения',
    duration: 'Длительность',
    name: 'Название',
    assignedTo: 'Ответственный',
    contact: 'Контакт',
    contactFace: 'Контактное лицо',
    dueDate: 'Срок выполнения',
    taskDueDate: 'Планируемая дата завершения',
    description: 'Описание',
    deal: 'Сделка',
    taskDeal: 'Лид / Сделка',
    department: 'Подразделение',
    transactionsSum: 'Сумма сделок',
    opportunities: 'Сделки',
    companyName: 'Название компании',
    website: 'Веб-сайт',
    gender: 'Пол',
    applicationName: 'Имя соискателя',
    personalEmail: 'Личный Email',
    mobile: 'Личный телефон',
    desiredPosition: 'Желаемая должность',
    jobType: 'Тип занятости',
    jobPositionName: 'Название должности',
    parentDepartment: 'Родительское подразделение',
    departmentManager: 'Руководитель подразделения',
    departmentName: 'Название подразделения',
  },
  buttons: {
    delete: 'Удалить { name }',
    addContact: 'Добавить контакт',
    choseContact: 'Выбрать контакт',
    createDeal: 'Создать сделку',
    createTask: 'Создать задачу',
    addAttachment: 'Добавить вложение',
    addNote: 'Добавить примечание',
    backToLead: 'Вернуть сделку в лид',
    configureFields: 'Настроить поля',
    saveFieldsConfiguration: 'Сохранить изменения',
    addCustomField: 'Добавить поле',
  },
  backToLeadInfo: 'Выберите стадию в которой будет находиться лид после преобразования из сделки.',
  followers: 'Наблюдатели',
  noFollowersYet: 'Пока нет наблюдателей',
  startFollowing: 'Стать наблюдателем',
  stopFollowing: 'Перестать быть наблюдателем',
  addFollower: 'Выбрать наблюдателя',
  tags: 'Теги',
  noTagsYet: 'Пока нет тегов',
  addTag: 'Выберите тег',
  tabs: {
    main: 'Основное',
    contacts: 'Контакты',
    addresses: 'Адрес',
    opportunities: 'Сделки',
    notes: 'Примечания',
    history: 'История',
    tasks: 'Задачи',
    files: 'Файлы',
    description: 'Описание',
    personalInfo: 'Личные данные',
    career: 'Карьера',
    roles: 'Роли и права',
    offer: 'Оффер',
  },
  declinedEntities: {
    employee: 'сотрудника',
    jobPosition: 'должность',
    department: 'подразделение',
    application: 'кандидата',
    company: 'компанию',
    lead: 'лид',
    opportunity: 'сделку',
    contact: 'контакт',
    task: 'задачу',
    subtask: 'подзадачу',
  },
  workflowStatus: {
    New: 'Новый',
    'In Progress': 'В работе',
    Pending: 'Ожидание',
    Done: 'Готово',
    Cancelled: 'Отмена',
    Hired: 'Нанят',
  },
};
