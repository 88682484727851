const style = '; font-weight: bold; text-transform: uppercase;';

const ProductionWarningMessage = `
Открытие инструментов разработчика может быть небезопасным для ваших личных данных.
Функция инструментов разработчика позволяет просматривать и изменять основной код веб-сайтов, что может привести к раскрытию конфиденциальной информации, такой как учетные данные для входа в систему и личные данные.
Используйте инструменты разработчика только в том случае, если вы осознаете все риски и понимаете, что делаете.

Рекомендуется закрыть инструменты разработчика, когда вы закончили их использовать.`;

export interface ILogger extends Console {
  init: (...args: any[]) => void;
  success: (...args: any[]) => void;
  request: (...args: any[]) => void;
  message: (...args: any[]) => void;
  debug: (...args: any[]) => void;
  failure: (...args: any[]) => void;
  socket: (...args: any[]) => void;
}

function loggerInit(): ILogger {
  switch (import.meta.env.VITE_LOG_LEVEL) {
  case '2': {
    const initialMessage = init(console);
    initialMessage('Log Level is Debug, resolve all console methods');
    return {
      ...console,
      init: initialMessage,
      success,
      request,
      message,
      debug,
      failure,
      socket,
    };
  }

  case '1': {
    const initialMessage = init(console);
    initialMessage('Log Level is Staging, resolve any console methods');
    return {
      ...console,
      init: initialMessage,
      error: (...args) => console.error(args),
      info: (...args) => console.info(args),
      request,
      failure,
      socket,

      // disabled
      log: disabled,
      table: disabled,
      trace: disabled,
      warn: disabled,
      success: disabled,
      message: disabled,
      debug: disabled,
      group: disabled,
      groupCollapsed: disabled,
      groupEnd: disabled,
      time: disabled,
      timeEnd: disabled,
      timeLog: disabled,
      profile: disabled,
      profileEnd: disabled,
      timeStamp: disabled,
    };
  }

  case '0':
  default: {
    const initialMessage = init(console, 'Внимание!');
    initialMessage(ProductionWarningMessage);

    const oldConsole = console;
    const OverrideConsole: ILogger = {
      ...console,
      init: initialMessage,
      error: (...args) => oldConsole.error(args),

      // disabled
      failure: disabled,
      log: disabled,
      table: disabled,
      info: disabled,
      trace: disabled,
      warn: disabled,
      success: disabled,
      request: disabled,
      message: disabled,
      socket: disabled,
      debug: disabled,
      group: disabled,
      groupCollapsed: disabled,
      groupEnd: disabled,
      time: disabled,
      timeEnd: disabled,
      timeLog: disabled,
      profile: disabled,
      profileEnd: disabled,
      timeStamp: disabled,
    };

    window.console = OverrideConsole;
    return OverrideConsole;
  }
  }
}

const init = (oldConsole: Console, title = 'Initial Message:') => (...args: any[]) => {
  return oldConsole.log(`%c${title}`, `color: #4ea53e ${style}`, ...args);
};

const success = (...args: any[]) => {
  const atLine = '\n\n' + new Error().stack!.split('\n')[2].trim();
  // return console.log('%cSuccess:', `color: green ${style}`, ...args, atLine)
};

const request = (...args: any[]) => {
  const atLine = '\n\n' + new Error().stack!.split('\n')[2].trim();
  // return console.log('%cRequest:', `color: #7b347b ${style}`, ...args, atLine)
};

const message = (...args: any[]) => {
  const atLine = '\n\n' + new Error().stack!.split('\n')[2].trim();
  return console.log('%cMessage:', `color: #1e9cb0 ${style}`, ...args, atLine);
};

const socket = (...args: any[]) => {
  const atLine = '\n\n' + new Error().stack!.split('\n')[2].trim();
  return console.log('%cSocket message:', `color: #9e39bf ${style}`, ...args, atLine);
};

const debug = (...args: any[]) => {
  const atLine = '\n\n' + new Error().stack!.split('\n')[2].trim();
  return console.log('%cDebug:', `color: #e7a457 ${style}`, ...args, atLine);
};

const failure = (...args: any[]) => {
  const atLine = '\n\n' + new Error().stack!.split('\n')[2].trim();
  return console.error('%cFailure:', `color: red ${style}`, ...args, atLine);
};

const disabled = (..._args: any[]): void => {};

export const logger = loggerInit();
