export default {
  buttons: {
    create: 'Создать контакт',
  },
  labels: {
    first: 'Имя',
    last: 'Фамилия',
    jobPosition: 'Должность',
    email: 'Email',
    phone: 'Мобильный телефон',
    deleteLocal: 'Удалить только из этой сделки',
    deleteGlobal: 'Удалить из общего справочника',
    company: {
      simple: 'Компания',
    },
    customer: 'Покупатель',
    supplier: 'Поставщик',
  },
  filters: {
    Supplier: 'Поставщик',
    Customer: 'Покупатель',
  },
  placeholders: {
    first: 'Введите имя',
    last: 'Введите фамилию',
    company: {
      simple: 'Выберите компанию',
    },
    jobPosition: 'Введите должность',
    dateBirth: 'Укажите дату рождения',
    email: 'Введите email',
    phone: 'Введите мобильный телефон',
    searchByName: 'Поиск контакта по имени и фамилии',
  },
  modal: {
    title: {
      delete: 'Удаление контакта',
      create: 'Создание нового контакта',
      multipleDelete: 'Удаление контактов',
    },
    body: {
      delete: 'Удалить контакт?',
      multipleDelete: 'Удалить выбранные контакты?',
    },
  },
};
