import type { RouteLocationRaw } from 'vue-router';
import { ElNotification } from 'element-plus';

// import type { IModule } from '/@/types/permission.model';

import { i18n } from '/@/lang';
// import { closeShade } from '/@/utils/pageShade';
import { logger } from '/@/utils/logger';
import router from '/@/router/index';
// import { useConfig } from '/@/stores';

export const routePush = (to: RouteLocationRaw, uniqueName?: string) => {
  try {
    if (uniqueName) {
      history.state.uniqueName = uniqueName;
    }
    router.push(to);
  } catch (error) {
    router.push({ name: 'notFound' });
    ElNotification({
      message: i18n.global.t('utils.Navigation failed, invalid route!'),
      type: 'error',
    });

    logger.failure({ error });
  }
};
