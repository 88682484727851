export default {
  weeklyTitle: 'Статистика Автобота за последние 14 дней',
  summaryTitle: 'Общая статистика Автобота',
  marksTitle: 'Статистика оценок за последние 14 дней',
  auto: 'Автоответ',
  manual: 'Ручной ответ',
  noAnswer: 'Без ответа',
  reviews: 'Отзывы',
  answerType: 'Тип ответа',
  sum: 'Всего',
  count: 'Кол-во',
  clear: 'Очистить фильтр',
  allCategory: 'Все категории товаров',
  oneStar: '1 звезда',
  twoStar: '2 звезды',
  threeStar: '3 звезды',
  fourStar: '4 звезды',
  fiveStar: '5 звезд',
  noAPI: {
    text: 'Для отображения статистики Автобота добавьте в свойствах хотя бы одного продавца параметры подключения к маркетплейсу',
    button: 'Настроить продавцов',
  },
};
