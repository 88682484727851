export default {
  titles: {
    add: 'Создание новой роли',
    edit: 'Редактирование роли',
  },
  dialog: {
    delete: {
      title: 'Удаление роли',
      messageOne: 'Удалить роль?',
      messageMany: 'Удалить выбранные роли?',
      hint: 'Работа пользователей, использующих эту роль, будет заблокирована до присвоения им другой действующей роли.',
    },
  },
  profileName: 'Название роли',
  read: 'Просмотр',
  delete: 'Удаление',
  write: 'Редактирование',
  moduleName: 'Название модуля',
  addFrom: 'Копировать разрешения из роли (опционально)',
  profileDescription: 'Описание роли',
  placeholders: {
    roleDescription: 'Введите описание роли',
    roleName: 'Введите название роли',
    copyRules: 'Выберите роль из которой нужно скопировать разрешения',
  },
  createRole: 'Создать роль',
  permissions: 'Разрешения',
  validate: {
    enterRoleName: 'Введите название роли',
  },
};
