import {LocationQueryValue} from 'vue-router';
import {useRepo} from 'pinia-orm';
import {AbilityBuilder, createMongoAbility} from '@casl/ability';
import createAxios from '/@/utils/axios';
import {ProfilesPermissions, Permissions, Profiles} from '/@/stores/profiles';
import {Users} from '/@/stores/users';
import {routePush} from '/@/utils/router';
import {useUserInfo} from '/@/stores';
import {Local} from '/@/utils/storage';
import ability from '../utils/ability';
import ModuleEnum from '../types/module.enum';
import {FeedbackTemplates, WBUsers} from '/@/views/autobot/store';
import {Schedule} from '/@/views/notifications/store';

import type ICurrentUser from '../types/current-user.model';

export interface LoginData {
  login: string
  pass: string
  rememberMe: boolean
  dbId?: string
  'g-recaptcha-response': string
}


export function forgotPassword(data: { email: string }): ApiPromise {
  return createAxios({
    url: 'api/users/forgotPassword',
    data: {
      email: data.email,
    },
    method: 'post',
  }, {
    showErrorMessage: false,
  }).then((res: any) => {
    return res;
  });
}

export function login(data: LoginData): ApiPromise {
  // const {getWbTokensList} = WildberriesService();
  return createAxios({
    url: 'api/users/login',
    data,
    method: 'post',
  }, {
    showErrorMessage: false,
  })
    .then(async() => {
      await currentUser().then(() => {
        Local.set('authorized', true);
      });
      routePush({name: 'erp'});
    });
}

export function loginWithSecret(secret: LocationQueryValue): ApiPromise {
  return createAxios({
    url: 'api/users/login',
    params: {
      secret,
    },
    method: 'get',
  }).then(async() => {
    await currentUser().then(() => {
      Local.set('authorized', true);
    });
    routePush({name: 'erp'});
  });
}

export function currentUser() {
  const profilesRepo = useRepo(Profiles);
  const permissionsRepo = useRepo(Permissions);
  const profilesPermissionsRepo = useRepo(ProfilesPermissions);
  const usersRepo = useRepo(Users);
  const userInfo = useUserInfo();

  return createAxios({
    url: 'api/users/current',
    method: 'get',
  })
    .then((res: any) => {
      if (res.user) {
        const {profilesPermissions, permissions, profiles} = ProfilesPermissions.parseFromProfile(res.user.profile);
        const current = Users.parse(res.user, true);
        giveAbility(res);

        profilesRepo.save(profiles);
        permissionsRepo.save(permissions);
        profilesPermissionsRepo.save(profilesPermissions);
        usersRepo.save(current);

        userInfo.setUserInfo(res.user);
        userInfo.setUserFilters(res.savedFilters);
        Local.set('userInfo', res.user);
        Local.set('userModules', res.user.profile.profileAccess);

        window.jivo_onLoadCallback = function() {
          window.jivo_api.setContactInfo({
            name: res.user.contactName.first + ' ' + res.user.contactName.last,
            email: res.user.email,
            phone: res.user.mobilePhone,
          });
        };

        return res.user;
      }
      return res;
    })
    .catch((error) => {
      routePush({name: 'login'});
      throw error;
    });
}

function giveAbility(res: ICurrentUser) {
  const {can, rules} = new AbilityBuilder(createMongoAbility);
  for (const rule of res.user.profile.profileAccess) {
    if (rule.access.del) {
      can('delete', ModuleEnum[rule.module]);
    }
    if (rule.access.editWrite) {
      can('write', ModuleEnum[rule.module]);
    }
    if (rule.access.read) {
      can('read', ModuleEnum[rule.module]);
    }
  }
  ability.update(rules);
}

export function logout() {
  const url = '/api/logout';
  return fetch(url, {
    redirect: 'manual',
  })
    .then((response) => {
      if (response.status === 200) {
        routePush({name: 'login'});
        clearPersonalData();
      } else {
        throw 'CANT LOGOUT!';
      }
    })
    .catch((error) => {
      clearPersonalData();
      throw error;
    });
}

const clearPersonalData = () => {
  const profilesRepo = useRepo(Profiles);
  const permissionsRepo = useRepo(Permissions);
  const profilesPermissionsRepo = useRepo(ProfilesPermissions);
  const usersRepo = useRepo(Users);
  const userInfo = useUserInfo();
  const wbUsersRepo = useRepo(WBUsers);
  const feedbackTemplateRepo = useRepo(FeedbackTemplates);
  const schedulesRepo = useRepo(Schedule);

  profilesRepo.flush();
  permissionsRepo.flush();
  profilesPermissionsRepo.flush();
  usersRepo.flush();
  userInfo.clear();
  wbUsersRepo.flush();
  feedbackTemplateRepo.flush();
  schedulesRepo.flush();
  userInfo.clear();
  wbUsersRepo.flush();
  feedbackTemplateRepo.flush();
  schedulesRepo.flush();
  wbUsersRepo.flush();
  feedbackTemplateRepo.flush();
  schedulesRepo.flush();
  Local.set('authorized', false);
};
