export default {
  modal: {
    title: {
      delete: 'Удаление тега',
      edit: 'Редактирование тега',
      create: 'Добавить новый тег',
    },
    body: {
      delete: 'Подтвердите удаление тега',
    },
  },
  labels: {
    name: 'Название тега',
    color: 'Цвет тега',
  },
};
