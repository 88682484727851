const general = () => import('/src/views/settings/modules/settings-general/settings-general.vue');
const workflows = () => import('/src/views/settings/modules/settings-workflows/settings-workflows.vue');

export default [
  {
    path: 'settings/general',
    name: 'SettingsGeneral',
    component: general,
    meta: {
      title: 'settingsGeneral',
    },
  },
  {
    path: 'settings/workflows',
    name: 'SettingsWorkflows',
    component: workflows,
    meta: {
      title: 'settingsWorkflows',
    },
  },
];
