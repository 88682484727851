
export const Local = {
  set(key: string, val: any) {
    window.localStorage.setItem(key, JSON.stringify(val));
  },
  get(key: string) {
    const json: any = window.localStorage.getItem(key);
    return JSON.parse(json);
  },
  has(key: string) {
    return Boolean(window.localStorage.getItem(key));
  },
  remove(key: string) {
    window.localStorage.removeItem(key);
  },
  clear() {
    window.localStorage.clear();
  },
};

export const Session = {
  set(key: string, val: any) {
    window.sessionStorage.setItem(key, JSON.stringify(val));
  },
  get(key: string) {
    const json: any = window.sessionStorage.getItem(key);
    return JSON.parse(json);
  },
  has(key: string) {
    return Boolean(window.sessionStorage.getItem(key));
  },
  remove(key: string) {
    window.sessionStorage.removeItem(key);
  },
  clear() {
    window.sessionStorage.clear();
  },
};

export const Cookie = {
  // copypasted from https://github.com/KanHarI/vue3-cookies/blob/master/src/index.ts#L197
  isKey(keyName: string): boolean {
    return new RegExp(
      '(?:^|;\\s*)' +
        encodeURIComponent(keyName).replace(/[\-\.\+\*]/g, '\\$&') +
        '\\s*\\='
    ).test(document.cookie);
  },
};
