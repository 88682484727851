const generator = () => import('/@/views/ai/modules/generator/generator.vue');

export default [
  {
    path: 'ai/generator',
    name: 'AIGenerator',
    component: generator,
    meta: {
      title: 'generator',
    },
  },
];
