import '/@/styles/index.scss';

import App from './App.vue';
import ElementPlus from 'element-plus';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import TextClamp from 'vue3-text-clamp';
import VueCookies from 'vue3-cookies';
import { components } from '/@/components';
import { createApp } from 'vue';
import { directives } from '/@/utils/directives';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import FloatingVue from 'floating-vue';
import { abilitiesPlugin } from '@casl/vue';
// modules import mark, Please do not remove.
import { loadLang } from '/@/lang/index';
import mitt from 'mitt';
import { registerIcons } from '/@/utils/common';
import router from './router';
import store from '/@/stores/index';
import ability from './utils/ability';

import TerpoSelect from '/@/components/terpo-select/terpo-select.vue';
import { initYandexMetrika } from 'yandex-metrika-vue3';
import type { Config as YaMConfig } from 'yandex-metrika-vue3/src/types';
import VueJivosite from '@bchteam/vue-jivosite';
import JivoConfig from '/@/constants/const.jivo-config';
import * as Sentry from '@sentry/vue';
import { Local } from '/@/utils/storage';

library.add(fas);
library.add(far);

async function start() {
  const app = createApp(App);
  app.use(store);
  const i18n = await loadLang(app);

  app.use(router);
  app.use<any>(ElementPlus, { size: 'default', i18n: i18n.global.t });
  app.use<any>(TextClamp);
  app.use<any>(VueCookies);
  app.use(FloatingVue);
  app.use(abilitiesPlugin, ability);
  app.component('FontAwesomeIcon', FontAwesomeIcon);
  app.component('TerpoSelect', TerpoSelect);
  // Jivo
  app.use(VueJivosite, { widgetId: JivoConfig.ID });

  // global registration
  components(app);
  // directives
  directives(app);
  // icons
  registerIcons(app);

  app.mount('#app');
  // modules start mark, Please do not remove.

  app.config.globalProperties.eventBus = mitt();

  const devEnvironment = import.meta.env.VITE_ENV;

  if (devEnvironment === 'production') {
    Sentry.init({
      app,
      environment: devEnvironment,
      dsn: import.meta.env.VITE_SENTRY_DSN,
      integrations: [
        new Sentry.BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        }),
        new Sentry.Replay(),
      ],
      release: __SENTRY_RELEASE__,
      // Performance Monitoring
      tracesSampleRate: 1.0,
      // Session Replay
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
    });

    Sentry.setUser({
      email: Local.has('userInfo') ? Local.get('userInfo').email : 'Unknown user',
      location: window.location.origin,
    });

    if (__YANDEX_METRIKA_KEY__) {
      app.use<YaMConfig>(initYandexMetrika, {
        id: __YANDEX_METRIKA_KEY__,
        router: router,
        env: devEnvironment,
        scriptSrc: 'https://mc.yandex.ru/metrika/tag.js',
      });
    }
  }
}
start();
