export interface WysiwygTool {
  template: string,
  name?: string,
  value?: string,
  icon?: string,
  tooltip?: string,
}

export interface WysiwygToolGroup {
  name: string
  controls: WysiwygTool[]
  icon?: string
  collapse?: boolean
  tooltip?: string
} 

export enum WysiwygTemplatesEnum {
  NAME = '${name}',
  VALUE = '${value}',
  SELECTED = '${selected}',
}

export interface WysiwygReplaceData {value: string, pos: number}
