export default {
  getAllLicenses: 'list',
  activateLicense: 'activate/',
  deactivateLicense: 'disable/',
  suspendLicense: 'pause/',
  resumeLicense: 'start/',
  enableAutoProlongation: 'enable_auto_prolongation/',
  disableAutoProlongation: 'disable_auto_prolongation/',
  getBillingHistory: 'billing/oplogPage',
  getBalance: 'billing/balance',
  proceedToYookassa: 'billing/yookassa/confirmation_url',
};
