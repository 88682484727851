import { App, nextTick } from 'vue';
import { ElForm } from 'element-plus';
import { useTitle } from '@vueuse/core';
import * as elIcons from '@element-plus/icons-vue';

import router from '/@/router/index';
import { useNavigationTabs, useSiteConfig } from '/@/stores';
import { i18n } from '../lang';
import { getUrl } from './axios';
import pagesTitle from '/@/lang/ru/pages-title';
import { prepareModulesForNavigation } from '/@/utils/load-module';

import Icon from '/@/components/icon/index.vue';
import { Local } from '/@/utils/storage';

export function registerIcons(app: App) {
  app.component('Icon', Icon);

  const icons = elIcons as any;
  for (const i in icons) {
    app.component(`el-icon-${icons[i].name}`, icons[i]);
  }
}

export function loadCss(url: string): void {
  const link = document.createElement('link');
  link.rel = 'stylesheet';
  link.href = url;
  link.crossOrigin = 'anonymous';
  document.getElementsByTagName('head')[0].appendChild(link);
}
export function getCssVar(name: string, query?: string) {
  let element = document.documentElement;
  if (query) {
    element = document.querySelector(query) || document.documentElement;
  }
  return getComputedStyle(element).getPropertyValue(`--${name}`);
}

export function loadJs(url: string): void {
  const link = document.createElement('script');
  link.src = url;
  document.body.appendChild(link);
}

export function setTitleFromRoute() {
  nextTick().then(() => {
    const route = router.currentRoute.value;
    let webTitle = '';
    if (Local.has('serverModules')) {
      const { preparedNavigation } = prepareModulesForNavigation();
      const matchedRoute = preparedNavigation.find((module) => module?.to && route.name === module.to.name);
      webTitle = matchedRoute?.mname;
    } else {
      webTitle = pagesTitle[route.meta.title as keyof typeof pagesTitle];
    }
    useTitle(webTitle);
  });
}

export function isExternal(path: string): boolean {
  return /^(https?|ftp|mailto|tel):/.test(path);
}

export const debounce = (fn: Function, ms: number) => {
  return (...args: any[]) => {
    if (window.lazy) {
      clearTimeout(window.lazy);
    }
    window.lazy = setTimeout(() => {
      fn(...args);
    }, ms);
  };
};

export const getArrayKey = (arr: any, pk: string, value: string): any => {
  for (const key in arr) {
    if (arr[key][pk] == value) {
      return key;
    }
  }
  return false;
};

export const onResetForm = (formEl: InstanceType<typeof ElForm> | undefined) => {
  if (!formEl) return;
  formEl.resetFields && formEl.resetFields();
};

export const buildJsonToElTreeData = (data: any): ElTreeData[] => {
  if (typeof data == 'object') {
    const childrens = [];
    for (const key in data) {
      childrens.push({
        label: key + ': ' + data[key],
        children: buildJsonToElTreeData(data[key]),
      });
    }
    return childrens;
  }
  return [];
};

export const isAdminApp = (path = '') => {
  if (path) {
    return /^\/admin/.test(path);
  }
  if (/^\/admin/.test(router.currentRoute.value.fullPath) || window.location.hash.indexOf('#/admin') === 0) {
    return true;
  }
  return false;
};

export const isMobile = () => {
  return !!navigator.userAgent.match(
    /android|webos|ip(hone|ad|od)|opera (mini|mobi|tablet)|iemobile|windows.+(phone|touch)|mobile|fennec|kindle (Fire)|Silk|maemo|blackberry|playbook|bb10\; (touch|kbd)|Symbian(OS)|Ubuntu Touch/i,
  );
};

export const getFileNameFromPath = (path: string) => {
  const paths = path.split('/');
  return paths[paths.length - 1];
};

export const auth = (name: string) => {
  const { navigationTabs } = useNavigationTabs();
  return !!(
    navigationTabs.authNode.has(router.currentRoute.value.path) &&
    navigationTabs.authNode.get(router.currentRoute.value.path)!.some((v: string) => v == router.currentRoute.value.path + '/' + name)
  );
};

export const fullUrl = (relativeUrl: string, domain = '') => {
  const siteConfig = useSiteConfig();
  if (!domain) {
    domain = siteConfig.cdnUrl ? siteConfig.cdnUrl : getUrl();
  }
  if (!relativeUrl) return domain;

  const regUrl = new RegExp(/^http(s)?:\/\//);
  const regexImg = new RegExp(/^((?:[a-z]+:)?\/\/|data:image\/)(.*)/i);
  if (!domain || regUrl.test(relativeUrl) || regexImg.test(relativeUrl)) {
    return relativeUrl;
  }
  return domain + relativeUrl;
};

export const checkFileMimetype = (fileName: string, fileType: string) => {
  if (!fileName) return false;
  const siteConfig = useSiteConfig();
  const mimetype = siteConfig.upload.mimetype.toLowerCase().split(',');

  const fileSuffix = fileName.substring(fileName.lastIndexOf('.') + 1).toLowerCase();
  if (siteConfig.upload.mimetype === '*' || mimetype.includes(fileSuffix) || mimetype.includes('.' + fileSuffix)) {
    return true;
  }
  if (fileType) {
    const fileTypeTemp = fileType.toLowerCase().split('/');
    if (mimetype.includes(fileTypeTemp[0] + '/*') || mimetype.includes(fileType)) {
      return true;
    }
  }
  return false;
};

export const arrayFullUrl = (relativeUrls: string | string[], domain = '') => {
  if (typeof relativeUrls === 'string') {
    relativeUrls = relativeUrls == '' ? [] : relativeUrls.split(',');
  }
  for (const key in relativeUrls) {
    relativeUrls[key] = fullUrl(relativeUrls[key], domain);
  }
  return relativeUrls;
};

export const getGreet = () => {
  const now = new Date();
  const hour = now.getHours();
  let greet = '';

  if (hour < 5) {
    greet = i18n.global.t('utils.Late at night, pay attention to your body!');
  } else if (hour < 9) {
    greet = i18n.global.t('utils.good morning!') + i18n.global.t('utils.welcome back');
  } else if (hour < 12) {
    greet = i18n.global.t('utils.Good morning!') + i18n.global.t('utils.welcome back');
  } else if (hour < 14) {
    greet = i18n.global.t('utils.Good noon!') + i18n.global.t('utils.welcome back');
  } else if (hour < 18) {
    greet = i18n.global.t('utils.good afternoon') + i18n.global.t('utils.welcome back');
  } else if (hour < 24) {
    greet = i18n.global.t('utils.Good evening') + i18n.global.t('utils.welcome back');
  } else {
    greet = i18n.global.t('utils.Hello!') + i18n.global.t('utils.welcome back');
  }
  return greet;
};

export const now = () => {
  const date = new Date();
  return {
    int: date.getTime(),
    iso: date.toISOString(),
    string: date.toString(),
  };
};

function decodeURLRecursively(url: string) {
  if (url.indexOf('%') != -1) {
    return decodeURLRecursively(decodeURIComponent(url));
  }

  return url;
}

export const hash2Obj = (hash: string) => {
  if (hash.substr(1) === '') return {};

  return hash
    ? hash
        .substr(1)
        .split('&')
        .map((v) => v.split('='))
        .reduce((pre, [key, value]) => ({ ...pre, [key]: decodeURLRecursively(value) }), {})
    : {};
};

export const obj2Hash = (obj: any) => {
  if (typeof obj !== 'undefined' && obj !== null) {
    const newHash = Object.keys(obj)
      .map((key) => {
        return `&${key}=${obj[key]}`;
      })
      .join('');

    return `#${newHash.substr(1)}`;
  }
  return null;
};
