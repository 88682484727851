export default {
  week: {
    ANY: ' - ',
    Monday: 'Пн',
    Tuesday: 'Вт',
    Wednesday: 'Ср',
    Thursday: 'Чт',
    Friday: 'Пт',
    Saturday: 'Сб',
    Sunday: 'Вс',
    Weekdays: 'Будни',
    Weekend: 'Выходные',
    Every: 'Каждый',
  },
  period: {
    hourly: "Каждый час",
    daily: "Eжедневно",
    weekly: "Еженедельно",
    monthly: "Ежемесячно",
  },
  inputType: {
    minute: 'Минуты',
    hour: 'Часы',
    dayOfMonth: 'Дни Месяца',
    month: 'Месяца',
    dayOfWeek : 'Дни Недели',
  },
  extType: {
    step: 'Каждый',
    list: 'Список',
    range: 'Период',
  }
}
