export default {
  buttons: {
    create: 'Создать кандидата',
    transferToEmployee: 'Перевести в сотрудники',
    transfer: 'Перевести',
    refuse: 'Отказать',
    sack: 'Уволить сотрудника',
    addJobNote: 'Добавить запись',
    confirmSack: 'Уволить',
  },
  labels: {
    workflow: 'Стадия',
    desiredPosition: 'Желаемая должность',
    personalEmail: 'Личный E-mail',
    mobile: 'Личный телефон',
    dateBirth: 'День рождения',
  },
  placeholders: {
    workflow: 'Выберите стадию',
    desiredPosition: 'Выберите должность',
    dateBirth: 'Укажите день рождения',
    searchByName: 'Поиск кандидата по имени и фамилии',
    currency: 'Валюта',
  },
  modal: {
    create: {
      title: 'Создание нового кандидата',
      alert: 'При создании кандидата заполните основные сведения.' +
        ' Дополнительную информацию и вложения можно добавить после создания кандидата.',
    },
    title: {
      delete: 'Удаление кандидата',
      multipleDelete: 'Удаление кандидатов',
      transfer: 'Перевод в сотрудники',
      refuse: 'Отказать кандидату',
    },
    body: {
      delete: 'Удалить кандидата?',
      multipleDelete: 'Удалить выбранных кандидатов?',
      transfer: 'Принять кандидата на работу с переводом в сотрудники?',
      multipleTransfer: 'Принять выбранных кандидатов на работу с переводом в сотрудники?',
      refuse: 'Отказать кандидату?',
      multipleRefuse: 'Отказать выбранным кандидатам?',
    },
  },
};
