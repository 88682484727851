export default {
  reach: ' Reach ',
  register: 'Register',
  'Via email': 'By email',
  'Via mobile number': 'By mobile number',
  account: 'Username/Email/Mobile',
  'Verification Code': 'Captcha',
  mobile: 'mobile',
  send: 'send',
  seconds: 'seconds',
  'Remember me': 'Remember me',
  'Forgot your password?': 'Forgot your password?',
  'Back to login': 'Back to login',
  'No account yet? Click Register': 'No account yet? Click Register',
  'Retrieve password': 'Retrieve password',
  'Retrieval method': 'Retrieval method',
  'New password': 'New password',
  second: 'second',
  'Account name': 'Account name',
  'Login success': 'Login success',
  'Please enter an account': 'Please enter your account',
  'Please input a password': 'Please enter your password',
  'Please enter the verification code': 'Please enter the Captcha',
  'Hold session': 'Keep the session',
  'Sign in': 'Sign in',
  'The verification code length must be between 4 and 6 bits': 'The Captcha length must be between 4 and 6 digit.',
}