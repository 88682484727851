export default {
  buttons: {
    create: 'Создать задачу',
    createSubtask: 'Создать подзадачу',
    transformToTask: 'Преобразовать в задачу',
  },
  labels: {
    category: 'Категория',
    workflow: 'Стадия',
    dueDate: 'Планируемая дата завершения',
    opportunitie: 'Лид или сделка',
    salesPerson: 'Ответственный',
    contact: 'Контакт',
    company: 'Компания',
    subtask: 'Надзадача',
    opportunitieProp: 'Лид / Сделка',
    description: 'Описание',
  },
  placeholders: {
    category: 'Выберите категорию',
    workflow: 'Выберите стадию',
    dueDate: 'Выберите дату завершения задачи',
    opportunitie: 'Выберите лид или сделку',
    salesPerson: 'Выберите ответственного',
    contact: 'Выберите контакт',
    company: 'Выберите компанию',
    subtask: 'Выберите задачу',
    description: 'Опишите задачу',
    opportunitieProp: 'Выберите лида / сделку',
  },
  addCategory: 'Добавить категорию',
  modal: {
    title: {
      delete: 'Удаление задачи',
      multipleDelete: 'Удаление задач',
      multipleDeleteSubtask: 'Удаление подзадач',
      createTask: 'Создание новой',
      editTask: 'Редактирование',
      task: 'задачи',
      subtask: 'подзадачи',
    },
    alert: 'При создании задачи заполните основные сведения. Дополнительную информацию и вложения можно добавить после создания задачи.',
    body: {
      delete: 'Удалить задачу?',
      multipleDelete: 'Удалить выбранные задачи?',
      multipleDeleteSubtask: 'Удалить выбранные подзадачи?',
      parentTaskDelete: 'Удалить задачу и все подзадачи?',
      deleteSubtask: 'Удалить задачу?',
    },
    validators: {
      dueDate: 'Срок выполнения подзадачи не должен быть позже завершения надзадачи',
      dueTime: 'Выберите время позднее текущего',
    },
  },
  filters: {
    placeholders: {
      searchByWorkFlow: 'Выберите стадию',
      searchByAssigned: 'Выберите ответственного',
      searchByName: 'Поиск задачи по названию',
      searchSubtaskByName: 'Поиск подзадачи по названию',
    },
  },
  popup: {
    assignedTo: 'Перейти к информации о сотруднике ',
    opportunity: 'Перейти к информации о лиде/сделке ',
    parentTaskInfo: 'Перейти к информации о задаче',
  },
};
