export default {
  route: 'Лид:',
  search: 'Поиск',
  createLead: 'Создать лид',
  deleteLead: 'Удалить',
  table: {
    leadsCount: 'Всего {count} строк',
  },
  modal: {
    title: {
      create: 'Создание нового лида',
      delete: 'Удаление лида',
      multipleDelete: 'Удаление лида',
      createDeal: 'Создать потенциальную сделку',
      tags: 'Теги',
      followers: 'Список наблюдателей',
      addTag: 'Добавить тег',
      addAttachment: 'Добавить вложение',
    },
    body: {
      delete: 'Удалить лид?',
      multipleDelete: 'Удалить выбранные лиды?',
      create: {
        alert:
          'При создании лида заполните основные сведения о запросе клиента. ' +
          'Дополнительную информацию и вложения можно добавить после создания лида.',
      },
      createNewClient: 'Создать нового клиента',
      dontCreateNewClient: 'Не создавать нового клиента',
    },
  },
  placeholders: {
    name: 'Название',
    note: 'Добавьте заметку... До 500 символов',
    description: 'Введите описание до 2500 символов',
    expectedRevenue: 'Введите сумму заказа',
    source: 'Выберите источник получения информации клиентом',
    requestType: 'Выберите тип обращения, первичное или повторное',
    salesPerson: 'Выберите ответственного',
    contact: 'Выберите контакт',
    contactFace: 'Выберите контакт',
    searchByName: 'Поиск лида по названиию',
    company: {
      simple: 'Выберите компанию',
      name: {
        first: 'Выберите компанию',
      },
    },
    createCompany: 'Создать новую компанию',
    dateBirth: 'Укажите дату рождения',
    contactName: {
      first: 'Введите имя',
      last: 'Введите фамилию',
    },
    workflow: 'Выберите стадию',
    phones: {
      phone: 'Введите номер телефона',
    },
    email: 'Введите Email',
    status: 'Выберите статус',
    priority: 'Выберите приоритет',
    jobPosition: 'Введите должность',
    social: {},
    address: {
      country: 'Выберите страну',
      zip: 'Введите почтовый индекс',
      state: 'Укажите регион',
      city: 'Укажите город',
      street: 'Введите улицу',
    },
    date: 'Дата',
  },
  labels: {
    requestType: 'Обращение',
    category: 'Категория',
    company: {
      simple: 'Компания',
      name: {
        first: 'Название компании',
      },
    },
    description: 'Описание',
    dealSource: 'Источник',
    email: 'Email',
    expectedRevenue: {
      name: 'Планируемая сумма заказа',
      value: 'Сумма',
      currency: 'Валюта',
    },
    dateBirth: 'Дата рождения',
    expectedClosing: 'Планируемая дата завершения',
    name: 'Название',
    workflow: 'Стадия',
    priority: 'Приоритет',
    salesPerson: 'Ответственный',
    source: 'Источник',
    contactName: {
      simple: 'Контакт',
      face: 'Контактное лицо',
      first: 'Имя',
      last: 'Фамилия',
    },
    phones: {
      phone: 'Телефон',
    },
    tags: 'Теги',
    changeDate: 'Дата изменения',
    duration: 'Длительность',
    social: {},
    jobPosition: 'Должность',
    address: {
      country: 'Страна',
      zip: 'Почтовый индекс',
      state: 'Регион',
      city: 'Город',
      street: 'Улица',
    },
    dueDate: 'Срок выполнения',
    amount: 'Сумма',
    contactFace: 'Контактное лицо',
    phone: 'Телефон',
    note: 'Примечание',
  },
  tabs: {
    main: 'Основное',
    contacts: 'Контакты',
    addresses: 'Адреса',
    notes: 'Примечания',
  },
};
