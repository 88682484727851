export function toBool(value: any) {

  if (typeof value == 'string') {
    switch (value.toLowerCase()) {
      case 'true': {
        return true
      }
      case 'false':
      default: {
        return false
      }
    }
  } else {
    return Boolean(value)
  }
}