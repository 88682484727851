export default {
  autobot: {
    labels: {
      seller: 'Наименование продавца',
      sellerPlaceholder: 'Введите наименование продавца',
      tokenForAutobot: 'Токен для Автобота',
      tokenForAnalyser: 'Токен для Аналитики',
      wbKeyCommon: 'Токен для Автобота',
      wbKeyStat: 'Токен для Аналитики',
      wbKeyAd: 'WB API: Реклама',
      dataError: 'Данные не корректны',
      marketplace: 'Маркетплейс',
      marketplacePlaceholder: 'Выберите маркетплейс',
      loginInput: 'Логин',
      enterLogin: 'Введите логин',
      token: 'Токен',
      enterToken: 'Введите токен',
      status: 'Статус',
      statisticTokenInfo: 'Токен для Аналитики и Поставок',
      settingsTokenInfo: 'Токен для Автобота',
      settingsTokenWB: 'Токен для отзывов Wildberries',
    },
    dialog: {
      delete: {
        messageOne: 'Удалить продавца?',
        messageMany: 'Удалить выбранных продавцов?',
        type: 'Удаление продавца',
      },
      error: {
        message: 'Проверьте правильность ввода токенов API и сохраните свойства продавца снова.',
        alert: 'Введен недействительный токен, сохранение невозможно.',
        title: 'Ошибка сохранения свойств продавца',
        ozonAlert: 'Введен недействительный токен, сохранение невозможно.',
        ozonMessage: 'Проверьте правильность ввода токена и сохраните свойства продавца снова.',
      },
    },
    modal: {
      title: {
        edit: 'Редактирование продавца',
        create: 'Создание продавца',
      },
      placeholders: {
        seller: 'Введите имя пользователя',
        API: 'Введите API токен',
        APIError: 'Недействительный API токен',
      },
      wbKeyPass: {
        ok: 'Ок',
        notOk: 'Доступ не предоставлен',
      },
      wbKeyCommon: {
        pass: 'Токен API для Автобота должен иметь актуальный срок действия и перечисленные разрешения:',
        checkExpDate: 'Срок действия:',
        checkContentAccess: 'Контент:',
        checkQAFAccess: 'Вопросы и отзывы:',
        checkRecommendationsAccess: 'Рекомендации:',
        checkChanges: 'Изменения:',
      },
      wbKeyStat: {
        pass: 'Токен API для Аналитики и Поставок должен иметь актуальный срок действия и перечисленные разрешения:',
        checkExpDate: 'Срок действия:',
        checkContentAccess: 'Контент:',
        staticticsAccess: 'Статистика:',
        marketplacesAccess: 'Маркетплейсы:',
      },
    },
    hint: {
      generateKey: 'Для подключения к Wildberries сгенерируйте токены API в своем ',
      link: 'личном кабинете',
      afterLink: ' Wildberries.',
      message1head: 'Для Автобота создайте токен с категориями:',
      message1listItem1: '"Контент"',
      message1listItem2: '"Вопросы и отзывы"',
      message1listItem3: '"Рекомендации"',
      message2head: 'Для Аналитики (включая планирование поставок и статистику продаж) создайте токен с категориями:',
      message2listItem1: '"Контент"',
      message2listItem2: '"Статистика"',
      message2listItem3: '"Маркетплейсы"',
      deleteSeller: `Удаление продавца приведёт к отключению работы
      SalesArea с данными этого продавца, получаемыми от торговой площалки.`,
      forAnalytics: 'Для работы Аналитики и Логистики создайте токен с доступом к API: “Статистика”, “Аналитика”, “Контент”',
      forAutobot: 'Для работы Автобота создайте токен с доступом к API: “Вопросы и отзывы”, “Рекомендации”, “Контент”.',
      createOzonSeller: 'Для подключения к OZON используйте токен авторизованной учетной записи OZON. Способ получения токена описан ',
      createOzonSellerLink: 'здесь',
    },
  },
  profile: {
    labels: {
      userName: 'Имя пользователя',
      email: 'Email',
      phone: 'Мобильный телефон',
      Password: 'Пароль',
      newPassword: 'Новый пароль',
      confirmPassword: 'Подтвердите новый пароль',
      currentPassword: 'Текущий пароль',
    },
  },
};
