import {computed, reactive} from 'vue';
import {defineStore} from 'pinia';
import {STORE_CONFIG} from '/@/stores/constant/cacheKey';
import {Layout} from '/@/stores/interface';

export const useConfig = defineStore(
  'config',
  () => {
    const layout: Layout = reactive({
      showDrawer: false,
      shrink: false,
      layoutMode: 'Default',
      mainAnimation: 'slide-right',
      isDark: false,
      menuBackground: ['#1D3043', '#1D3043'],
      menuColor: ['#ffffff', '#ffffff'],
      menuActiveBackground: ['#2A598A', '#2A598A'],
      menuActiveColor: ['#409eff', '#409eff'],
      menuTopBarBackground: ['#1D3043', '#1D3043'],
      menuWidth: 250,
      menuDefaultIcon: 'el-icon-Minus',
      menuCollapse: false,
      menuUniqueOpened: false,
      menuShowTopBar: true,
      headerBarTabColor: ['#A3A6AD', '#A3A6AD'],
      headerBarTabActiveBackground: ['#2A598A', '#2A598A'],
      headerBarTabBackground: ['#213D5B', '#213D5B'],
      headerBarTabActiveColor: ['#FFFFFF', '#FFFFFF'],
      headerBarBackground: ['#1D3043', '#1D3043'],
      headerBarHoverBackground: ['#2A598A', '#2A598A'],
    });

    const lang = reactive({
      defaultLang: 'ru',
      fallbackLang: 'en',
      langArray: [
        {name: 'ru', value: 'Russian'},
        {name: 'en', value: 'English'},
      ],
    });

    function menuWidth() {
      if (layout.shrink) {
        return layout.menuCollapse ? '0px' : layout.menuWidth + 'px';
      }
      return layout.menuCollapse ? '64px' : layout.menuWidth + 'px';
    }

    function setLang(val: string) {
      lang.defaultLang = val;
    }

    function onSetLayoutColor(data = layout.layoutMode) {
      const tempValue = layout.isDark ? {idx: 1, color: '#1d1e1f', newColor: '#141414'} : {idx: 0, color: '#ffffff', newColor: '#f5f5f5'};
      if (
        data == 'Classic' &&
                layout.headerBarBackground[tempValue.idx] == tempValue.color &&
                layout.headerBarTabActiveBackground[tempValue.idx] == tempValue.color
      ) {
        layout.headerBarTabActiveBackground[tempValue.idx] = tempValue.newColor;
      } else if (
        data == 'Default' &&
                layout.headerBarBackground[tempValue.idx] == tempValue.color &&
                layout.headerBarTabActiveBackground[tempValue.idx] == tempValue.newColor
      ) {
        layout.headerBarTabActiveBackground[tempValue.idx] = tempValue.color;
      }
    }

    function setLayoutMode(data: string) {
      layout.layoutMode = data;
      onSetLayoutColor(data);
    }

    const setLayout = (name: keyof Layout, value: any) => {
      layout[name] = value as never;
    };

    const getColorVal = function(name: keyof Layout): string {
      const colors = layout[name] as string[];
      if (layout.isDark) {
        return colors[1];
      }
      return colors[0];
    };

    const getDarkMode = computed(() => layout.isDark);

    return {layout, lang, menuWidth, setLang, setLayoutMode, setLayout, getColorVal, onSetLayoutColor, getDarkMode};
  },
  {
    persist: {
      key: STORE_CONFIG,
    },
  }
);
