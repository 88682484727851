import {io} from 'socket.io-client';
import {logger} from '/@/utils/logger';

export const socket = io({
  path: '/api/socket.io',
  transports: ['polling'],
  autoConnect: false,
});

socket.on('open', () => {
  logger.socket('open');
});

socket.on('connect', () => {
  logger.socket('connect');
});

socket.on('disconnect', () => {
  logger.socket('disconnect');
});

socket.on('connect_error', () => {
  logger.socket('error');
});
