export default {
  placeholders: {
    findNmId: 'Поиск товара по артикулу WB',
    wbWh: 'Склад Wildberries',
    selfWh: 'Склад продавца Wildberries',
    retro3: 'Ретроспективный период 3 дня',
    retro7: 'Ретроспективный период 7 дней',
    retro14: 'Ретроспективный период 14 дней',
    retro30: 'Ретроспективный период 30 дней',
    retro90: 'Ретроспективный период 90 дней',
    chooseDay: 'Выберите день',
    supplyDay: 'Дата поставки',
    dataSourceTypes: {
      ORDERS: 'Темп заказов',
      SALES: 'Темп продаж',
    },
    calcTypes: {
      TOTAL: 'Расчёт темпа за дни наличия товара на складе',
      AVG: 'Расчёт темпа за весь ретроспективный период',
    },
  },
  table: {
    productName: 'Наименование товара',
    nmId: 'Артикул WB',
    supplierId: 'Артикул продавца',
    whOwner: 'Тип склада',
    address: 'Адрес',
    slSpeed1: 'Темп заказов в день, шт',
    slSpeed2: 'Темп продаж в день, шт',
    qty: 'В наличии, шт',
    inWayToWh: 'В пути, шт',
    inWayFromClient: 'Возврат на склад, шт',
    qtyPredict: 'Прогноз наличия, дн',
    whTurns: 'Планируемая оборачиваемость, дн',
    expSupply: 'Планируемая поставка, шт',
    total: 'Всего, шт',
    whName: 'Название склада',
    totalTime: 'Наличие на складе, дн',
    qtySales: 'Продано, шт',
    qtyOrders: 'Заказано, шт',
    dataSource1: 'Продано, шт',
    dataSource2: 'Заказано, шт',
  },
  supplyDay: 'Дата поставки: ',
  noData: `Для точного расчёта плана поставок пока недостаточно накопленных данных.
   Расчёт базируется на меньшем ретроспективном периоде и носит приблизительный характер.`,
  findNmId: 'Поиск товара по артикулу WB',
  searchByNmId: 'Поиск поставок по этому артикулу',
  onlyRecommend: `Расчет основан на данных о наличии товаров на складе из API Wildberries “Статистика”
   с использованием ваших настроек склада и носит рекомендательный характер.`,
};
