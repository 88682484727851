import { defineStore } from 'pinia';
import type { ILicense } from '/@/types/licenses';
interface IState {
  licenses: ILicense[];
}

export const useLicensesInfo = defineStore('licensesInfo', {
  state: (): IState => {
    return {
      licenses: [],
    };
  },
  actions: {
    setLicenses(state: ILicense[]) {
      this.licenses = [...state];
    },
  },
  getters: {
    hasInactiveLicenses: (state): boolean => !state.licenses.some((item) => !!item.invoice === true),
    hasLicenseById: (state) => {
      return (licenseId: string): boolean => !!state.licenses.find((item) => item._id === licenseId);
    },
  },
});
