export default {
  route: 'Сделка: ',
  modal: {
    title: {
      create: 'Создание новой сделки',
      delete: 'Удаление сделки',
      multipleDelete: 'Удаление сделки',
      backToLead: 'Возврат сделки в лид',
    },
    body: {
      delete: 'Удалить сделку?',
      multipleDelete: 'Удалить выбранные сделки?',
      create: {
        alert: `При создании сделки заполните основные сведения о запросе клиента.
        Дополнительную информацию и вложения можно добавить после создания сделки.`,
      },
      warning: 'Выберите стадию в которой будет находиться лид после преобразования из сделки',
      stage: 'Стадия',
    },
  },
  placeholders: {
    contact: 'Выберите или создайте новый контакт',
    company: 'Выберите или создайте новую компанию',
    internalNotes: 'Введите текст примечания до 2500 символов',
    requestType: 'Выберите тип обращения, первичное или повторное',
    searchByName: 'Поиск сделки по названиию',
  },
  labels: {
    internalNotes: 'Примечание',
    company: 'Название компании',
  },
  tabs: {
    main: 'Основное',
    contacts: 'Контакты',
    addresses: 'Адреса',
    notes: 'Примечания',
  },
};
