import type {App} from 'vue';
import {createI18n, Composer, I18n} from 'vue-i18n';

import elementEnLocale from 'element-plus/dist/locale/en.mjs';
import elementRuLocale from 'element-plus/dist/locale/ru.mjs';

import {isEmpty} from 'lodash-es';
import {logger} from '/@/utils/logger';
import {useConfig} from '/@/stores/config';

export let i18n: {
  global: Composer
};
const assignLocale = {
  ru: [elementRuLocale],
  en: [elementEnLocale],
};

export async function loadLang(app: App) {
  const config = useConfig();
  const locale = config.lang.defaultLang;

  const lang = await import(`./globs-${locale}.ts`);
  const message = lang.default ?? {};

  if (locale == 'ru') {
    window.loadLangHandle = {
      ...import.meta.glob('./ru/**/*.ts'),
      ...import.meta.glob('./ru/index.ts'),
      // ...import.meta.glob('./frontend/ru/**/*.ts'),
      // ...import.meta.glob('./frontend/ru.ts'),
    };
  } else {
    window.loadLangHandle = {
      ...import.meta.glob('./en/**/*.ts'),
      ...import.meta.glob('./en/index.ts'),
      // ...import.meta.glob('./frontend/en/**/*.ts'),
      // ...import.meta.glob('./frontend/en.ts'),
    };
  }

  let localeMessage = {};
  if (locale == 'ru') {
    localeMessage = getLangFileMessage(import.meta.glob('./pages/ru/**/*.ts', {eager: true}), locale);
    assignLocale[locale].push(getLangFileMessage(import.meta.glob('./ru/**/*.ts', {eager: true}), locale));
  } else if (locale == 'en') {
    localeMessage = getLangFileMessage(import.meta.glob('./pages/en/**/*.ts', {eager: true}), locale);
    assignLocale[locale].push(getLangFileMessage(import.meta.glob('./en/**/*.ts', {eager: true}), locale));
  }
  assignLocale[locale].push(localeMessage);
  if (!isEmpty(localeMessage)) {
    logger.error('cant find locales in /@/lang');
  }

  const messages = {
    [locale]: {
      ...message,
    },
  };

  Object.assign(messages[locale], ...assignLocale[locale]);

  i18n = createI18n({
    locale: locale,
    legacy: false,
    globalInjection: true,
    fallbackLocale: config.lang?.fallbackLang || 'ru',
    messages,
  });

  app.use(i18n as I18n);
  return i18n;
}

function getLangFileMessage(mList: any, locale: string) {
  let msg: anyObj = {};
  locale = '/' + locale;
  for (const path in mList) {
    if (mList[path].default) {
      const pathName = path.slice(path.lastIndexOf(locale) + (locale.length + 1), path.lastIndexOf('.'));
      if (pathName.indexOf('/') > 0) {
        msg = handleMsglist(msg, mList[path].default, pathName);
      } else {
        msg[pathName] = mList[path].default;
      }
    }
  }
  return msg;
}

export function mergeMessage(message: anyObj, pathName = '') {
  if (isEmpty(message)) return;
  if (!pathName) {
    return i18n.global.mergeLocaleMessage(i18n.global.locale.value, message);
  }
  let msg: anyObj = {};
  if (pathName.indexOf('/') > 0) {
    msg = handleMsglist(msg, message, pathName);
  } else {
    msg[pathName] = message;
  }
  i18n.global.mergeLocaleMessage(i18n.global.locale.value, msg);
}

export function handleMsglist(msg: anyObj, mList: anyObj, pathName: string) {
  const pathNameTmp = pathName.split('/');
  let obj: anyObj = {};
  for (let i = pathNameTmp.length - 1; i >= 0; i--) {
    if (i == pathNameTmp.length - 1) {
      obj = {
        [pathNameTmp[i]]: mList,
      };
    } else {
      obj = {
        [pathNameTmp[i]]: obj,
      };
    }
  }
  return mergeMsg(msg, obj);
}

export function mergeMsg(msg: anyObj, obj: anyObj) {
  for (const key in obj) {
    if (typeof msg[key] == 'undefined') {
      msg[key] = obj[key];
    } else if (typeof msg[key] == 'object') {
      msg[key] = mergeMsg(msg[key], obj[key]);
    }
  }
  return msg;
}

export function editDefaultLang(lang: string): void {
  const config = useConfig();
  config.setLang(lang);
  location.reload();
}
