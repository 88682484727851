import { Model } from 'pinia-orm';
import { WorkerState } from '/@/types/states.enum';
import { defineStore } from 'pinia';
import NotificationService from './api';

export const useActionsList = defineStore('$ScheduledActions', {
  state: () => ({
    list: [],
    stamp: 0,
  }),
  actions: {
    async load(modulePermissions: any) {
      const stamp = Date.now() / 1000;
      if (this.$state.stamp + 5 * 60 > stamp) {
        return false;
      }

      const list = await NotificationService.loadActions(modulePermissions).catch(() => []);

      this.$patch({
        list: new Array(...list),
        stamp,
      });
    },
  },
});
export class Schedule extends Model {
  static entity = '[Schedules]';
  static primaryKey: string | string[] = '_id';

  get id() {
    return this._id;
  }

  static fields() {
    return {
      _id: this.string(null),
      action: this.string(''),
      cronRule: this.string('* * * * *'),
      userId: this.string(''),
      recipient: this.string(''),
      state: this.attr(WorkerState.UNKNOWN),
    };
  }

  static parse(schedule: Partial<Schedule>) {
    return new Schedule(schedule);
  }

  declare _id: string;
  declare action: string;
  declare cronRule: string;
  declare userId: string;
  declare recipient: string;
  declare state: WorkerState;
}
