<template>
  <el-select
    :model-value="modelValue"
    :value-key="valueKey"
    filterable
    :filter-method="filterMethod"
    class="w100"
    :clearable="clearable ? clearable : false"
    :placeholder="placeholder"
    placement="bottom"
    popper-class="terpo-select__dropdown"
    ref="terpoSelect"
    @change="valueHandler"
    @clear="emits('update:model-value', null)"
  >
    <el-option v-if="state.isListEmpty" value="''" :label="items.length === 0 ? $t('noData') : $t('noResults')" disabled />
    <el-option
      v-for="item in state.propertyList"
      :key="item._id"
      :class="{ 'terpo-select__option': highOption }"
      :style="optionStyles"
      :value="valueProperty ? getProp(valueProperty, item) : item"
      :label="labelProperty ? getProp(labelProperty, item) : item"
    >
      <slot name="option" :item="item" />
    </el-option>
    <el-option :label="createButtonLabel ?? $t('add')" value="" class="terpo-select__add-property" @click="emits('create')" />
  </el-select>
</template>

<script setup lang="ts">
import { reactive, watch, ref } from 'vue';
import { getDeepPropertyByKey } from '/@/utils/formatting/object-formatting';

interface IProps {
  items: Record<string, unknown>[];
  highOption?: boolean;
  labelProperty: string;
  valueProperty?: string;
  valueKey: string;
  placeholder: string;
  modelValue: string | number | boolean | Record<string, any> | unknown[];
  optionStyles?: Record<string, unknown>;
  createButtonLabel?: string;
  clearable?: boolean;
}
interface IEmits {
  (event: 'create'): void;
  (event: 'update:model-value', value: unknown): void;
}
interface IState {
  propertyList: unknown[];
  isListEmpty: boolean;
}
const props = defineProps<IProps>();
const emits = defineEmits<IEmits>();
const state = reactive<IState>({
  propertyList: [],
  isListEmpty: true,
});
const terpoSelect = ref();
const filterMethod = (value: string) => {
  if (value) {
    state.isListEmpty = false;
    let result = [];
    if (value.length > 0) {
      result = props.items.filter((item) => (item[props.labelProperty] as string).toLowerCase().includes(value.toLowerCase()));
      if (result.length === 0) {
        state.propertyList = [];
        state.isListEmpty = true;
      } else {
        state.propertyList = result;
      }
    }
  } else {
    state.propertyList = [...props.items];
  }
};
const valueHandler = (value: unknown) => {
  if (value) {
    emits('update:model-value', value);
  }
};
const initData = () => {
  if (props.items.length === 0) {
    state.isListEmpty = true;
  } else {
    state.propertyList = [...props.items];
    state.isListEmpty = false;
  }
};
const getProp = (key: string, item: any): any => {
  return getDeepPropertyByKey(key, item);
};
watch(() => props.items, initData, { deep: true, immediate: true });
watch(
  () => props.modelValue?.companyInfo,
  () => terpoSelect.value.blur(),
);
</script>

<style lang="scss">
.terpo-select {
  &__add-property {
    width: 100%;
    border-top: 1px solid var(--el-border-color-light);
    color: var(--el-color-primary);
    height: 32px;
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: var(--el-bg-color);
  }

  &__option {
    &:hover {
      .contact__option-title {
        color: var(--el-color-primary);
        font-weight: 700;
      }
    }
  }

  &__dropdown {
    .el-select-dropdown__wrap {
      padding-bottom: 32px;
    }
  }
}
</style>
