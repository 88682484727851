export default {
  'ProcessState': {
    0: 'Неизвестно',
    1: 'В Процессе',
    2: 'В Ожидании',
    3: 'Успешно',
    4: 'Провалено',
    5: 'Ошибка'
  },
  'WorkerState': {
    0: 'Неизвестно',
    1: 'Активно',
    2: 'Ожидает',
    3: 'Остановлено',
    4: '',
    5: 'Ошибка',
  },
  'EntityState': {
    0: 'Неизвестно',
    1: 'Успешно',
    2: 'Провалено',
    3: 'Скрыто',
    4: '',
    5: 'Ошибка',
  },
}