import { AxiosRequestConfig } from 'axios';
import createAxios from '/@/utils/axios';
import { Schedule } from './store';
import type { ISchedulesParams } from '/@/types/schedules.model';

class NotificationService {
  private url: string = 'api/schedules';
  private url_v2: string = 'api/v2/schedules';

  createAxios(options: AxiosRequestConfig<any>) {
    return createAxios(options);
  }

  loadActions = ({ wb = false, ozon = false }): Promise<string[]> => {
    const promisses = [];

    if (wb) {
      promisses.push(
        this.createAxios({
          url: this.url + '/actions',
          method: 'GET',
        }),
      );
    }

    if (ozon) {
      promisses.push(
        this.createAxios({
          url: this.url_v2 + '/actions',
          method: 'GET',
        }),
      );
    }

    return Promise.all(promisses).then((actions) => {
      return Array.isArray(actions) && actions.length > 0 ? actions.flat() : [];
    });
  };

  create = (data: any = {}): ApiPromise => {
    return this.createAxios({
      url: data?.action === 'autobot:disassembleOzonFeedbacks' ? this.url_v2 : this.url,
      method: 'POST',
      data,
    }) as ApiPromise;
  };

  update = (data: Schedule): ApiPromise => {
    return this.createAxios({
      url: `${data?.action === 'autobot:disassembleOzonFeedbacks' ? this.url_v2 : this.url}/${data.id}`,
      method: 'PATCH',
      data,
    }) as ApiPromise;
  };

  stop = (schedule: Schedule): ApiPromise => {
    return this.createAxios({
      url: `${schedule.action === 'autobot:disassembleOzonFeedbacks' ? this.url_v2 : this.url}/stop/${schedule.id}`,
      method: 'PATCH',
    }) as ApiPromise;
  };

  start = (schedule: Schedule): ApiPromise => {
    return this.createAxios({
      url: `${schedule.action === 'autobot:disassembleOzonFeedbacks' ? this.url_v2 : this.url}/start/${schedule.id}`,
      method: 'PATCH',
    }) as ApiPromise;
  };

  delete = (id: Schedule['id'], actionType: Schedule['action']): ApiPromise => {
    return this.createAxios({
      url: `${actionType === 'autobot:disassembleOzonFeedbacks' ? this.url_v2 : this.url}/${id}`,
      method: 'DELETE',
    }) as ApiPromise;
  };

  load = (id: Schedule['id']): ApiPromise => {
    return this.createAxios({
      url: this.url + `/${id}`,
      method: 'GET',
    }) as ApiPromise;
  };

  loadAll = (params: ISchedulesParams): ApiPromise => {
    return this.createAxios({
      url: params?.action === 'autobot:disassembleOzonFeedbacks' ? this.url_v2 : this.url,
      method: 'GET',
      params,
    }) as ApiPromise;
  };
}

export default new NotificationService();
