export default {
  editUser: 'Редактирование пользователя',
  createUser: 'Создание пользователя',
  dialog: {
    delete: {
      messageOne: 'Удалить пользователя?',
      messageMany: 'Удалить выбранных пользователей?',
      titleOne: 'Удаление пользователя',
      titleMany: 'Удаление пользователей',
    },
    create: {
      useEmail: 'Используйте для авторизации электронную почту и пароль.',
      createRoles: 'Создание и выбор собственных ролей будут доступны в следующих версиях.',
      pass1: 'Для защиты данных используйте',
      pass2: 'безопасный пароль, содержащий:',
      checkLength: 'От 8 до 64 символов',
      checkLowerCase: 'Строчные латинские буквы',
      checkUpperCase: 'Прописные латинские буквы',
      checkDigits: 'Цифры',
      checkUtility: "Спецсимволы !{'@'}#$%^&*_",
      wrongOldPass: 'Неверный старый пароль',
    },
  },
  labels: {
    login: 'Имя пользователя',
    email: 'Электронная почта',
    department: 'Отдел',
    profile: 'Роль',
    lastSign: 'Последний вход',
    password: 'Пароль',
    passwordRetry: 'Подтвердите пароль',
  },
  placeholders: {
    login: 'Введите имя пользователя',
    firstName: 'Введите имя',
    lastName: 'Введите фамилию',
    email: 'Введите электронную почту',
    password: 'Введите пароль',
    passwordRetry: 'Введите пароль ещё раз',
    profile: 'Выберите роль пользователя',
  },
  pagination: {
    countLines: 'Всего { count } строк',
  },
};
