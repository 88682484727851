export default {
  tabs: {
    organization: 'Организация',
    systemNotification: 'Системные уведомления',
    regionalSettings: 'Региональные настройки',
  },
  currency: 'Основная валюта',
  user: 'Получатель уведомлений (пользователь)',
  currencyCode: 'Код валюты',
  symbol: 'Символ',
  decPlace: 'Количество знаков после запятой',
  active: 'Активна',
  state: 'Состояние',
  placeholders: {
    user: 'Выберите пользователя',
    currency: 'Выберите или добавьте новую валюту',
    currencyCode: 'Введите код валюты',
    symbol: 'Введите символ',
    decPlace: 'Выберите количество знаков от 0 до 3',
  },
  modal: {
    currency: {
      title: 'Добавление новой валюты',
    },
    workflow: {
      title: 'Редактирование рабочего процесса',
    },
    stageDelete: {
      title: 'Удаление стадии рабочего процесса',
      body: 'Удалить стадию?',
    },
  },
  buttons: {
    currency: 'Добавить новую валюту',
    user: 'Добавить нового пользователя',
    addWorkflow: 'Добавить стадию',
  },
  workflows: {
    'Applications': 'Кандидаты',
    'Leads': 'Лиды',
    'Projects': 'Проекты',
    'Purchase Invoice': 'Счет-фактуры (покупки)',
    'Purchase Order': 'Закупки (заказы)',
    'Sales Order': 'Заказы',
    'Project Tasks': 'Задачи (проекты)',
    'Sales Invoice': 'Счет-фактуры (продажи)',
    'DealTasks': 'Задачи (сделки)',
    'Jobs': 'Сотрудники',
    'Opportunities': 'Сделки',
    'Job positions': 'Должности',
  },
};
