export default {
  searchInput: 'Поиск отзывов по артикулу WB',
  searchInputArticle: 'Поиск отзывов по артикулу',
  countUnanswered: 'Всего: ',
  seller: 'Продавец',
  auto: 'Автоответ:',
  autoTag: 'Автоответ',
  manual: 'Ручной ответ:',
  manualTag: 'Ручной ответ',
  needToAnswerManual: 'Требуется ручной ответ',
  withAttach: 'С файлами:',
  allMarks: 'Все оценки',
  countLines: 'Всего { count } строк',
  answerType: 'Тип ответа',
  answer: 'Ответ',
  status: 'Статус',
  noAnswer: 'Без ответа',
  new: 'Новый',
  archiveWb: 'Архивные WB',
  exportExcel: 'Экспорт в XLS',
  createNew: 'Создать новый',
  processedBySA: 'Обработаны Sales Area',
  processedByWB: 'Обработаны Wildberries',
  startAutoAnswer: 'Запустить автоответ',
  clear: 'Очистить фильтр',
  processed: 'Обработанные',
  processedBy: 'Кем обработаны',
  templateQualities: 'Свойства шаблона',
  variant: 'Вариант ответа',
  openSchedule: 'Расписание запуска',
  noAPI: {
    text: 'Для отображения списка отзывов настройте продавца Wildberries и введите токен WB API для Автобота.',
    button: 'Настроить продавцов',
  },
  noUserName: 'Имя покупателя скрыто',
  ozon: {
    searchInput: 'Поиск отзывов по артикулу OZON',
    noAPI: {
      text: 'Для отображения списка отзывов настройте продавца OZON и введите его учетные данные.',
    },
    archiveOzon: 'Архивные OZON',
    modal: {
      advantages: 'Достоинства',
      flaws: 'Недостатки',
      comments: 'Комментарии',
    },
  },
  modal: {
    advantages: 'Достоинства',
    flaws: 'Недостатки',
    comments: 'Комментарий',
  },
};
