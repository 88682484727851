const ModuleEnum: Record<number, string> = {
  7: 'Users',
  51: 'Profiles',
  200: 'Autobot',
  201: 'Templates',
  203: 'Feedbacks',
  204: 'Statistics',
  205: 'Questions',
  206: 'QuestionsTemplates',
  1001: 'Sellers',
  1002: 'TasksSchedule',
};

export default ModuleEnum;
