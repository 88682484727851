import axios, { type AxiosPromise, type AxiosRequestConfig, type Method } from 'axios';
import { LoadingOptions } from 'element-plus';

import type IOptions from '/@/types/axios/IOptions';

import { useConfig } from '/@/stores';
import useInterceptors from '/@/utils/axios/interceptors';

window.requests = [];
window.tokenRefreshing = false;

export const getUrl = (): string => {
  const value: string = import.meta.env.VITE_AXIOS_BASE_URL as string;
  return value == 'getCurrentDomain' ? window.location.protocol + '//' + window.location.host : value;
};

function createAxios<T = any>(axiosConfig: AxiosRequestConfig, options: IOptions = {}, loading: LoadingOptions = {}): ApiPromise<T> {
  const config = useConfig();
  const Axios = axios.create({
    baseURL: getUrl(),
    timeout: 1000 * 10,
    headers: {
      'think-lang': config.lang.defaultLang,
      server: true,
    },
    responseType: 'json',
    ...requestPayload(axiosConfig.method as Method, axiosConfig.data),
  });
  useInterceptors(Axios, axiosConfig, options, loading);

  return options.reductDataFormat ? (Axios(axiosConfig) as ApiPromise) : (Axios(axiosConfig) as AxiosPromise);
}
export default createAxios;

export function requestPayload(method: Method, data: anyObj) {
  if (method == 'GET' || method == 'get') {
    return {
      params: data,
    };
  } else if (method == 'POST' || method == 'post') {
    return {
      data: data,
    };
  }
}

export function saveAsFile(url: string, name: string, target: string = '_self'): void {
  const a = document.createElement('a');

  a.setAttribute('style', 'display: none');
  a.href = url;
  a.download = name;
  a.target = target;

  function handleFileDownloadClick(event: Event): void {
    event.stopPropagation();
  }

  document.body.appendChild(a);
  a.addEventListener('click', handleFileDownloadClick, { once: true });
  a.click();
  document.body.removeChild(a);
}

export function saveAsFileWithType(data: ArrayBuffer, name: string, mimeType: string, target = '_self'): void {
  const a = document.createElement('a');
  const blob = new Blob([data], { type: mimeType });
  const url = window.URL.createObjectURL(blob);

  a.setAttribute('style', 'display: none');
  a.download = name;
  a.href = url;
  a.dataset.downloadurl = [mimeType, a.download, a.href].join(':');
  a.target = target;

  function handleFileDownloadClick(event: Event): void {
    event.stopPropagation();
  }

  document.body.appendChild(a);
  a.addEventListener('click', handleFileDownloadClick, { once: true });
  a.click();
  document.body.removeChild(a);
  window.URL.revokeObjectURL(url);
}
