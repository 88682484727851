export default {
  fillBalance: 'Пополнить',
  fillBalanceFor: 'Пополнить на <b>{amount} ₽<b/> ',
  autoProlongationOff: 'Продление отключено',
  autoProlongationOn: 'Продление включено',
  amountPerMonth: '{amount} ₽ / мес',
  subscribe: {
    activeUntil: 'Подписка действительна до',
    expiredAt: 'Оплата просрочена',
    trial: 'Пробный период до',
  },
  buy: 'Подключить',
  trial: 'Попробовать бесплатно',
  activateSuccessful: 'Подписка успешно подключена',
  tooltips: {
    fillBalance: 'Пополнить баланс',
    fillBalanceFor: 'Пополнить баланс на сумму, недостающую для продления подписки',
  },
  modal: {
    title: {
      manage: 'Управление подписками',
      switchOn: 'Подключение подписки',
      switchOff: 'Отключение продления',
      resume: 'Возобновление продления',
      trial: 'Подключение пробного периода',
    },
    alert: {
      manage: 'У вас нет ни одной действующей подписки.',
      switchOn: 'Подписка будет действовать до <b>{date}</b> и автоматически продлится при наличии средств на вашем балансе.',
      recharge: 'Для подключения подписки недостаточно средств на вашем балансе.',
      switchOff: 'Вы можете возобновить продление в любой момент до окончания действия текущей подписки.',
      resume: 'Подписка продолжит действие до <b>{date}</b> и автоматически продлится при наличии средств на вашем балансе.',
      trial: 'Пробный период будет действовать до <b>{date}</b> и автоматически переключится в платный режим при наличии средств на вашем балансе.',
    },
    text: {
      manage: 'Для использования сервиса перейдите в раздел “Подписки” в свойствах вашего аккаунта и оформите пробную или платную подписку.',
      switchOn: 'Для подключения подписки с вашего баланса будет списана сумма в размере <b>{amount}</b> <b> ₽</b>.',
      recharge: 'Для продолжения пополните баланс на сумму <b>{amount}</b> <b> ₽</b> и затем выполните подключение снова.',
      switchOff: 'Подписка продолжит действие до <b>{date}</b>, а затем будет автоматически отключена. ',
      resume: 'Следующее списание средств с вашего баланса в размере <b>{amount}</b> <b> ₽</b> будет выполнено <b>{date}</b>.',
      trial: 'Если пробный период пройдет успешно и вы не отключите автоматическое продление подписки, списание средств с вашего баланса в размере <b>{amount}  ₽</b> будет выполнено <b>{date}</b>.',
    },
    buttons: {
      manage: 'Управление подписками',
      switchOn: 'Подключить',
      recharge: 'Пополнить баланс на {amount} ₽',
      switchOff: 'Отключить',
      resume: 'Возобновить',
    },
  },
};
