export default {
  home: 'Домашняя',
  admin: 'Admin',
  login: 'Авторизация',
  notFound: 'Страница не найдена',
  noPower: 'Нет доступа',
  noTitle: 'No title',
  Loading: 'Загрузка...',
  erp: 'Домашняя',
  help: 'Справка',
  salesStat: 'Статистика продаж',
  Settings: 'Настройки',
  leads: 'Лиды',
  lead: 'Лид:',
  companies: 'Компании',
  company: 'Компания:',
  contacts: 'Контакты',
  contact: 'Контакт:',
  opportunities: 'Сделки',
  opportunitie: 'Сделка:',
  tasks: 'Задачи',
  task: 'Задача:',
  employees: 'Сотрудники',
  employee: 'Сотрудник: ',
  jobPositions: 'Должности',
  jobPosition: 'Должность: ',
  applications: 'Кандидаты',
  application: 'Кандидат: ',
  departments: 'Подразделения',
  department: 'Подразделение: ',
  settingsGeneral: 'Общие настройки',
  settingsWorkflows: 'Рабочие процессы',
  salesstat: 'Статистика продаж',
  licenses: 'Подписки',
  billing: 'История операций',
  warehouses: 'Склады Wildberries',
  supply: 'Планирование поставок',
  generator: 'AI генератор отзывов',
  questions: 'Вопросы',
  questionTemplates: 'Шаблоны для вопросов',
  questionsCategories: 'Категории вопросов',
  ozonReviews: 'Отзывы OZON',
  reviewsWB: 'Отзывы WB',
} as const;
