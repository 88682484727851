export default {
  title: 'Авторизация',
  enterUsername: 'Введите имя пользователя',
  enterEmail: 'Введите email',
  rememberMe: 'Запомнить меня',
  enterPassword: 'Введите пароль',
  restorePassword: 'Восстановить пароль',
  enter: 'Войти',
  detailed: 'Узнать больше',
  restorePasswordHint: 'Сообщение с новым паролем будет отправлено вам на электронную почту.',
  features: {
    title: 'Управляйте своими продажами в SALES AREA!',
    feature1: 'Анализируйте динамику продаж',
    feature2: 'Контролируйте поступление новых отзывов',
    feature3: 'Поручите рутинную обработку отзывов умному Автоботу',
  },
};
