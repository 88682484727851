export default {
  uniqueSeller: {
    tokenFor: 'Токен WB API для продавца',
    tokenExpired: 'больше недействителен.',
    tokenExpiring: 'скоро прекратит действие. ',
  },
  notUniqueSeller: {
    tokenExpired: 'Токены WB API для нескольких продавцов больше недействительны. ',
    tokenExpiring: 'Токены WB API для нескольких продавцов скоро прекратят действие. ',
  },
  proceedToSettings: ' Перейти к настройке',
  table: {
    tokenIsExpired: 'Токен не действителен',
    tokenIsExpiring: 'Действует еще {days} дн.',
  },
};
