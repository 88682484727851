export default {
  hints: {
    brand: 'Введите наименование бренда. При автоответе шаблон будет учитывать только карточки товаров указанного бренда',
  },
  feedbacks: {
    labels: {
      feedbacks: 'Отзывы',
      feedback: 'Отзыв',
      questions: 'Вопросы',
      new: 'Новые',
      arch: 'Архив',
      seller: 'Продавец',
      supplierArticle: 'Арт. продавца',
      wbArticle: 'Артикул WB',
      ozonArticle: 'Артикул OZON',
      feedbacksCount: 'Отзывов',
      categoryFeedbaks: 'Отзывов в категории',
      median: 'Средний рейтинг по категории',
      lowRate: 'Товар с самой низкой оценкой',
      maxRate: 'Товар с самой высокой оценкой',
      rate: 'Оценка',
      date: 'Дата',
      productName: 'Наименование товара',
      files: 'Файлы',
      answerClaim: 'Подать жалобу',
      answerClaimed: 'Жалоба подана',
      createTemplate: 'Создать новый шаблон',
      generatAIResponse: 'Сгенерировать ответ (ИИ)',
      waitResponse: 'Пожалуйста, подождите... Идет генерация ответа от нейросети.',
      responseTimeout: 'Нет ответа от нейросети. Повторите попытку позже.',
    },
    answered: {
      label: 'Отвечено',
      isAutoAnswered: 'Aвтоботом',
      isManualAnswered: 'Вручную',
    },
    buttons: {
      claim: 'Жалоба',
      clear: 'Oчистить',
      answer: 'Ответить',
      sendClaim: 'Подать жалобу',
      updateAnswer: 'Обновить ответ',
    },
    placeholders: {
      subject: 'По Категории',
      wbArticle: 'Введите артикул целиком',
      noTemplate: 'Нет шаблонов',
      chooseTemplate: 'Выберите ответ из шаблонов',
    },
    unauthorized: 'Обновите токен в настройках, пользователь не авторизован',
    dialog: {
      claim: {
        message: 'Подать жалобу на отзыв покупателя?',
        title: 'Подача жалобы',
        hint: 'Отзыв покупателя будет отправлен на проверку специалистам торговой площадки',
      },
      answer: {
        feedbackAnswer: 'Ответ на отзыв',
        updateAnswer: 'Редактирование ответа на отзыв',
      },
    },
    answer: {
      error: 'Ошибка отправки ответа',
      invalid: 'Ответ неверен',
    },
  },
  feedbackAnswer: {
    labels: {
      feedback: 'Отзыв',
      template: 'Шаблон ответа',
      answer: 'Ответ',
    },
  },
  templates: {
    buttons: {
      addTemplate: 'Создать шаблон',
      addVariant: 'Добавить вариант ответа',
    },
    placeholders: {
      enterBrands: 'Введите одно или несколько названий брендов',
      enterKeywords: 'Введите одно или несколько ключевых слов или фраз',
      tplName: 'Введите название шаблона',
      enterAnswer: 'Введите текст ответа',
      filterCompany: 'Поиск шаблона по продавцу',
      filterAnswer: 'Поиск шаблона по назначению ответа',
      filterAnswerType: 'Поиск шаблона по типу ответа',
      filterName: 'Поиск шаблона по названию',
      chooseCompany: 'Выберите продавца',
      chooseCategory: 'Выберите одну или несколько категорий товара',
      noCategory: 'Нет доступных для выбора категорий',
      marketplaces: 'Поиск шаблона по маркетплейсу',
    },
    modal: {
      title: {
        create: 'Создание шаблона ответа на отзыв',
        edit: 'Редактирование шаблона ответа на отзыв',
      },
    },
    labels: {
      tplFeedback: 'Шаблоны для отзывов',
      tplQuestions: 'Шаблоны для вопросов',
      tplType: 'Тип ответа',
      tplName: 'Название шаблона',
      tplText: 'Варианты ответа',
      rate: 'Оценки',
      claimRate: 'Жалоба на оценку',
      auto: 'Автоответ',
      manualAnswer: 'Ручной ответ',
      brand: 'Бренд',
      useBrand: 'Применять к отзывам с брендом',
      useCategory: 'Применять к отзывам с категорией товара (опционально)',
      useKeywords: 'Применять к отзывам с ключевыми словами',
      useStopKeywords: 'Не применять к отзывам со стоп-словами',
      useNmIds: 'Применять к отзывам с артикулами',
      excludePictures: 'Не применять к отзывам с изображениями',
      keywords: 'Ключевые слова',
      photos: 'Изображения',
      rateSetting: 'Применять к отзывам с оценкой',
      rateForUse: 'Учитывать рейтинг',
      rateForClaim: 'Пожаловаться на отзыв',
      name: 'Наименование шаблона',
      manual: 'Ручной',
      autoType: 'Автоматический',
      textTemplates: 'Варианты ответа',
      textTemplate: 'Вариант ответа',
      template: 'Вариант',
      variables: 'Используйте в тексте:',
      tplState: 'Состояние шаблона',
      state: 'Состояние',
      on: 'Включен',
      justAnswer: 'Ответ покупателю',
      answerWithClaim: 'Ответ покупателю с подачей жалобы',
      useWhat: 'Применять к отзывам, содержащим',
      useSeller: 'Применять к отзывам по продавцу',
      excludeWhat: 'Исключения (только ручной ответ)',
      conditionsUses: 'Условия применения',
      answerPurpose: 'Назначение ответа',
      answerVariants: 'Варианты ответов (варианты ответов будут использованы в случайном порядке)',
      answerVariantsLabel: 'Варианты ответов',
      answerType: 'Тип ответа',
      status: 'Статус',
      seller: 'Продавец',
      allAnswerTypes: 'Все типы ответов',
      allAnswerPurpose: 'Все назначения ответов',
      excludeText: 'Не применять к отзывам с текстом',
      textConditions: 'Применять в зависимости от наличия текста в отзыве',
      always: 'Применять всегда, независимо от наличия текста в отзыве',
      onlyWithText: 'Применять только к отзывам с текстом',
      onlyWithoutText: 'Применять только к отзывам без текста',
    },
    WYSIWYG: {
      prodname: 'Наименование товара',
      brandname: 'Наименование бренда',
      sellername: 'Наименование продавца',
      customername: 'Имя покупателя',
    },
    dialog: {
      delete: {
        messageOne: 'Удалить шаблон?',
        messageMany: 'Удалить выбранные шаблоны?',
        type: 'Удаление шаблона',
      },
    },
    hints: {
      'You can pass that': 'Вы можете добавить в текст шаблона следующее переменные',
      'Duplicate keyword another': 'Дублирование ключевого слова в другом шаблоне',
      'Duplicate keyword this': 'Дублирование ключевого слова в этом шаблоне',
      'Duplicate nmId this': 'Дублирование артикула в этом шаблоне',
      'Duplicate brand this': 'Дублирование бренда в этом шаблоне',
    },
  },
  valuation: {
    rating: 'Учитывать рейтинг',
    claimFeedback: 'Пожаловаться на отзыв',
  },
  tooltips: {
    brands: {
      title: 'Совет по выбору брендов',
      description:
        'Оставьте поле пустым, если ответ универсальный и подходит для товаров всех брендов текущего продавца. Если нет, перечислите нужные бренды.',
    },
    keywords: {
      title: 'Совет по выбору ключевых слов',
      description:
        'Ключевые слова - наиболее часто используемые слова и словосочетания в отзывах ваших покупателей. Например, «не подошло», «брак», «долгая доставка» и т.п. Обратите внимание, если указать «не подошло», то ответ сработает на отзывы с «не подошло», но пропустит отзывы, содержащие «подошло» или «не подходит».',
    },
    codes: {
      title: 'Совет по выбору артикулов',
      description: 'Оставьте поле пустым, если ответ универсальный и подходит для всех товаров. Если нет, перечислите нужные артикулы.',
    },
  },
};
