export default {
  name: 'Название компании',
  shortName: 'Краткое название',
  fullName: 'Полное название',
  activityType: 'Вид деятельности',
  relationshipType: 'Тип отношений',
  transactionsSum: 'Сумма сделок',
  transactions: 'Сделки',
  salesPerson: 'Ответственный',
  email: 'Email',
  phone: 'Телефон',
  source: 'Источник',
  creationDate: 'Дата создания',
  editDate: 'Дата изменения',
  create: 'Создать компанию',
  contact: 'Контактное лицо',
  addContact: 'Добавление контакта',
  webSite: 'Веб-сайт',
  opportunities: 'Сделки',
  buttons: {
    delete: 'Удалить компанию',
    addContact: 'Добавить контакт',
  },
  tabs: {
    main: 'Основное',
    contacts: 'Контакты',
    addresses: 'Адрес',
    opportunities: 'Сделки',
    notes: 'Примечания',
    history: 'История',
    tasks: 'Задачи',
    files: 'Файлы',
  },
  labels: {
    name: 'Название компании',
    fullName: 'Полное название компании',
    country: 'Страна',
    KPP: 'КПП',
    ogrn: 'ОГРН',
    TIN: 'ИНН',
    website: 'Сайт',
    jobPosition: 'Должность',
    email: 'Email',
    phone: 'Телефон',
    mobile: 'Мобильный телефон',
    address: {
      street: 'Улица',
      city: 'Город',
      state: 'Регион',
      zip: 'Почтовый индекс',
      country: 'Страна',
    },
    opportunities: {
      name: 'Наименование сделки',
      expectedRevenue: 'Ожидаемый доход',
      responsible: 'Ответственный',
      workflow: 'Статус',
    },
    newCompanyName: 'Новое, произвольное название компании',
    innKpp: 'ИНН {inn} / КПП {kpp}',
    transactionsSum: 'Сумма сделок',
    opportunitiesQuantity: 'Количество сделок',
    contact: 'Контактное лицо',
    salesPerson: 'Ответственный',
  },
  placeholders: {
    name: 'Введите название',
    companySearch: 'Для поиска компании введите точное название или ИНН',
    street: 'Введите улицу',
    country: 'Введите страну',
    zip: 'Введите индекс',
    state: 'Введите регион',
    city: 'Введите город',
    fullName: 'Введите полное название',
    KPP: 'Введите КПП',
    ogrn: 'Введите ОГРН',
    TIN: 'Введите ИНН',
    website: 'Введите  сайт',
    email: 'Введите email',
    phone: 'Введите телефон',
    mobile: 'Введите мобильный телефон',
    address: {
      country: 'Выберите страну',
      zip: 'Введите почтовый индекс',
      state: 'Укажите регион',
      city: 'Укажите город',
      street: 'Введите улицу',
    },
    relationshipType: 'Выберите тип отношений (покупатель, поставщик, и пр.)',
    searchByName: 'Поиск компании по названию',
    salesPerson: 'Выберите ответственного',
  },
  filters: {
    serviceOptions: {
      Supplier: 'Поставщик',
      Customer: 'Покупатель',
    },
  },
  modal: {
    title: {
      create: 'Создание новой компании',
      delete: 'Удаление компании',
      multipleDelete: 'Удалений компаний',
    },
    body: {
      create: {
        alert:
          'При создании компании заполните основные сведения. Дополнительную информацию и вложения можно добавить после создания компании.',
      },
      delete: 'Удалить компанию?',
      multipleDelete: 'Удалить компании?',
    },
  },
};
