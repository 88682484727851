<template>
  <el-config-provider :locale="lang">
    <router-view />
  </el-config-provider>
</template>

<script setup lang="ts">
import {onMounted, watch} from 'vue';
import {useI18n} from 'vue-i18n';
import {useRoute} from 'vue-router';
import iconfontInit from '/@/utils/iconfont';
import {setTitleFromRoute} from '/@/utils/common';
import {useConfig} from '/@/stores/config';
import {socket} from '/@/utils/socket.io';

const config = useConfig();
const route = useRoute();

// Initialize element's language pack
const {getLocaleMessage} = useI18n();
const lang = getLocaleMessage(config.lang.defaultLang) as any;

onMounted(() => {
  socket.connect();
  iconfontInit();
});

// Update browser headers when listening for route changes
watch(
  () => route.path,
  () => {
    setTitleFromRoute();
  }
);
</script>
