import { defineStore } from 'pinia'
import { ADMIN_INFO } from '/@/stores/constant/cacheKey'
import { AdminInfo } from '/@/stores/interface'

export const useAdminInfo = defineStore('adminInfo', {
  state: (): AdminInfo => {
    return {
      id: 0,
      username: '',
      nickname: '',
      avatar: '',
      lastlogintime: '',
      token: '',
      refreshToken: '',
      super: false,
    }
  },
  actions: {
    dataFill(state: AdminInfo) {
      this.$state = { ...this.$state, ...state }
    },
    removeToken() {
      this.token = ''
      this.refreshToken = ''
    },
    setToken(token: string, type: 'token' | 'refreshToken') {
      this[type] = token
    },
    getToken(type: 'auth' | 'refresh' = 'auth') {
      return type === 'auth' ? this.token : this.refreshToken
    },
    setSuper(val: boolean) {
      this.super = val
    },
  },
  persist: {
    key: ADMIN_INFO,
  },
})
