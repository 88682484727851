export default {
  name: 'Название подразделения',
  departmentManager: 'Руководитель подразделения',
  parentDepartment: 'Родительское подразделение',
  buttons: {
    create: 'Создать подразделение',
  },
  labels: {
    name: 'Название подразделения',
    departmentManager: 'Руководитель подразделения',
    parentDepartment: 'Родительское подразделение',
  },
  placeholders: {
    name: 'Введите название подразделения',
    departmentManager: 'Выберите руководителя подразделения',
    parentDepartment: 'Выберите родительское подразделение',
  },
  modal: {
    title: {
      delete: 'Удаление подразделения',
      multipleDelete: 'Удаление подразделения',
    },
    body: {
      delete: 'Удалить подразделение?',
      multipleDelete: 'Удалить выбранные подразделения?',
    },
    create: {
      title: 'Создание нового подразделения',
    },
  },
};
