<template>
  <el-option
    v-for="tpl in selectItems"
    :key="tpl.key"
    :label="tpl.label"
    :value="tpl"
  />
  <el-option
    value="false"
    disabled
  />
  <el-option
    value=""
    :label="createTitle"
    class="bottom-create"
    @click="(event: Event) => onCreate(event)"
  />
</template>

<script lang="ts">
import {defineComponent} from 'vue';

interface SelectItem {
  key: string;
  label: string;
  value: string;
}


export default defineComponent({
  name: 'TrpOptionsCustom',
  props: {
    selectItems: {
      required: true,
      type: Array as () => SelectItem[],
    },
    createTitle: {
      type: String,
      default: 'Создать новый',
    },
  },
  emits: ['create'],
  methods: {
    onCreate(event: any) {
      event.preventDefault();
      event.stopPropagation();

      this.$emit('create');
    },
  },

});
</script>

<style lang="scss" scoped>
.bottom-create {
  position: absolute;
  bottom: 6px;
  background-color: var(--el-bg-color);
  border-top: 1px solid var(--el-border-color);
  width: 100%;
  color: var(--el-color-primary);
  font-weight: bold;
}
</style>
