export default {
  today: 'Сегодня',
  yesterday: 'Вчера',
  last7days: 'Последние 7 дней',
  last14days: 'Последние 14 дней',
  last30days: 'Последние 30 дней',
  thisMounth: 'Этот месяц',
  prevMounth: 'Предыдущий месяц',
  startDate: 'Начальная дата',
  endDate: 'Конечная дата',
};
