export default {
    home: 'Home',
    admin: 'Admin',
    adminLogin: 'Login',
    notFound: 'Page not found',
    noPower: 'No access permission',
    noTitle: 'No title',
    Loading: 'Loading...',
    User: 'Member Center',
    userLogin: 'Menber Login',
    erp: 'ERP',
    Settings: 'Settings'
}
