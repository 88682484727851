import { Model } from 'pinia-orm';

import { defineStore } from 'pinia';
import { get } from 'lodash/fp';
import { useDateFormat } from '@vueuse/core';
import { now } from '/@/utils/common';
import { toBool } from '/@/utils/fix-types';

export const FeedbackTemplateDefaultID = 'NEW';
export enum FeedbackState {
  None = 'none',
}
export enum FeedbackValuation {
  None = 0,
  Poor = 1,
  Weak = 2,
  Good = 3,
  VeryGood = 4,
  Excellent = 5,
}

export enum DisassembleProcess {
  PROCESS = 1,
  SUCCESS = 2,
  UNKNOWN = 0,
}

export interface IUseByTextPresence {
  always: boolean;
  onlyWithText: boolean;
  onlyWithoutText: boolean;
}

export enum TextPresence {
  always = 'always',
  onlyWithText = 'onlyWithText',
  onlyWithoutText = 'onlyWithoutText',
}

export const useFeedbackState = defineStore('FeedbackState', {
  state: () =>
    ({
      disassembleProcess: DisassembleProcess.UNKNOWN,
    }) as {
      disassembleProcess: DisassembleProcess;
    },
  actions: {
    setDisassembleProcess(value: DisassembleProcess) {
      this.disassembleProcess = value;
    },
  },
});

export enum FeedbackListOrder {
  ASC = 'dateAsc',
  DESC = 'dateDesc',
  None = '',
}

export enum MPType {
  ozon = 'ozon',
  wb = 'wb',
}

export interface FeedbackListParams {
  isAnswered: boolean;
  /**
   * request list length
   * @summary : 1 - 10000
   */
  take?: number;
  /**
   * request list offset
   * @summary : 1 - 10000
   */
  skip?: number;
  /**
   * Sort by date
   * @summary
   */
  order: FeedbackListOrder;
  /**
   * Wb Article
   * @summary request list length : 1 - 10000
   */
  nmId: string;
  /**
   * Suppler claims
   * @summary request list length : 1 - 10000
   */
}

export interface FeedbackPhoto {
  fullSize: string;
  miniSize: string;
}

export interface FeedbackAnswer {
  text: string;
  supplierId?: number;
  employeeId?: number;
  metadata?: any;
  state?: string;
  editable?: boolean;
}
export class Feedback extends Model {
  static entity = '[Feedbacks]';
  static primaryKey: string | string[] = 'id';

  static fields() {
    return {
      id: this.string(''),
      imtId: this.number(0),
      subjectId: this.number(0),
      text: this.string('some text'),
      productValuation: this.number(0),
      createdDate: this.string(now().iso),
      updatedDate: this.string(now().iso),
      answer: this.attr(null),
      state: this.string(FeedbackState.None),
      photos: this.attr([]),
      photoLinks: this.attr([]),
      video: this.string(''),
      wasViewed: this.boolean(false),
      isCreationSupplierComplaint: this.boolean(true),
      supplierComplaint: this.attr(null),
      productDetails: this.hasOne(Products, 'feedback_id'),
      productOrders: this.hasMany(ProductsOrders, 'feedback_id'),
      product_imtId: this.attr(null),
      supplierId: this.attr(null),
    };
  }

  get createdDateString() {
    return useDateFormat(this.createdDate, 'DD.MM.YYYY').value;
  }

  get updatedDateString() {
    return useDateFormat(this.updatedDate, 'DD.MM.YYYY').value;
  }

  get nmId() {
    return get('nmId', this.productDetails);
  }

  get supplierArticle() {
    return get('supplierArticle', this.productDetails);
  }

  static parse(data: Partial<Feedback>) {
    return new Feedback({
      ...data,
      ...(data.productDetails
        ? {
            supplierId: data.productDetails.supplierId,
            product_imtId: data.productDetails?.imtId,
            productDetails: new Products({
              ...data.productDetails,
            }),
          }
        : {}),
      productOrders: data.productOrders?.map(
        (order: ProductsOrders) =>
          new ProductsOrders({
            ...order,
            feedback_id: data.id,
          }),
      ),
    });
  }

  declare id: string;
  declare imtId: number;
  declare subjectId: number;
  declare text: string;
  declare productValuation: FeedbackValuation;
  declare createdDate: string;
  declare updatedDate: string;
  declare answer: FeedbackAnswer;
  declare state: FeedbackState;
  declare productDetails: Products;
  declare productOrders: ProductsOrders[];
  declare photos: string[];
  declare photoLinks: FeedbackPhoto[];
  declare video: string;
  declare wasViewed: boolean;
  declare isCreationSupplierComplaint: boolean;
  declare product_imtId: any;
  declare supplierId: any;
  declare supplierComplaint: any;
}

export class Products extends Model {
  static entity = '[Products]';
  static primaryKey: string | string[] = 'imtId';
  static fields() {
    return {
      imtId: this.number(0),
      nmId: this.number(0),
      productName: this.string(''),
      supplierArticle: this.string(''),
      supplierId: this.number(0),
      supplierName: this.string(''),
      brandId: this.number(0),
      brandName: this.string(''),
    };
  }

  declare imtId: number;
  declare nmId: number;
  declare productName: string;
  declare supplierArticle: string;
  declare supplierId: number;
  declare supplierName: string;
  declare brandId: number;
  declare brandName: string;
  declare feedback_id: string;
}

export class ProductsOrders extends Model {
  static entity = '[ProductsOrders]';
  static primaryKey: string | string[] = 'shkId';
  static fields() {
    return {
      shkId: this.number(0),
      created: this.string(now().iso),
      feedback_id: this.attr(null),
    };
  }

  declare shkId: number;
  declare created: string;
}
export class FeedbackTemplates extends Model {
  static entity = '[FeedbackTemplates]';

  static primaryKey: string | string[] = '_id';
  static Rating: any = FeedbackValuation;

  static fields() {
    return {
      _id: this.attr(''),
      name: this.string(''),
      text: this.attr([]),
      brand: this.attr([]),
      valuation: this.attr([]),
      useKeywords: this.boolean(false),
      useStopwords: this.boolean(false),
      sendClaim: this.boolean(false),
      forAutoAnswer: this.boolean(true),
      claimValuation: this.attr([]),
      keywords: this.attr([]),
      stopwords: this.attr([]),
      nmIds: this.attr([]),
      algorithmRecommends: this.string(''),
      considePhotos: this.boolean(false),
      companyId: this.attr(''),
      enabled: this.boolean(false),
      category: this.attr([]),
      useByTextPresence: this.attr({}),
    };
  }

  save(data: any[]) {
    super.save(data);
  }

  static mutators() {
    return {
      forAutoAnswer: {
        set: (value: any) => toBool(value),
      },
      text: {
        set: (value: string | string[]) => {
          if (typeof value == 'string') {
            return [value];
          }
          return value;
        },
      },
    };
  }

  get id() {
    return this._id;
  }

  // declare localId: string;
  declare _id: any;
  declare name: string;
  declare text: string[];
  declare brand: string[];
  declare keywords: string[];
  declare category: string[];
  declare nmIds: string[];
  declare useKeywords: boolean;
  declare sendClaim: boolean;
  declare forAutoAnswer: boolean;
  declare valuation: FeedbackValuation[];
  declare claimValuation: FeedbackValuation[];
  declare algorithmRecommends: string;
  declare considePhotos: boolean;
  declare companyId: string;
  declare enabled: boolean;
  declare useByTextPresence: IUseByTextPresence;

  static parse(template: Partial<FeedbackTemplates>): FeedbackTemplates {
    return new FeedbackTemplates({
      ...template,
    });
  }
}

export class WBUsers extends Model {
  static entity = '[WBUsers]';

  static primaryKey: string | string[] = '_id';

  static fields() {
    return {
      _id: this.string(''),
      name: this.string(''),
      token: this.attr(null),
      xSupplierId: this.string(''),
      unauthorized: this.boolean(true),
      disabled: this.boolean(false),
      rating: this.number(5),
      selected: this.boolean(false),
      wbSettingsToken: this.string(''),
      wbStatisticToken: this.string(''),
      wbAdToken: this.string(''),
    };
  }

  get id() {
    return this._id;
  }

  declare _id: string;
  declare name: string;
  declare token?: any;
  declare xSupplierId: string;
  declare unauthorized: boolean;
  declare disabled: boolean;
  declare selected: boolean;
  declare rating: number;
  declare wbSettingsToken: string;
  declare wbStatisticToken: string;
  declare wbAdToken: string;

  static parse(user: Partial<WBUsers>): WBUsers {
    return new WBUsers({
      ...user,
    });
  }
}

export class MPSellers extends Model {
  static entity = '[MPSellers]';

  static primaryKey: string | string[] = '_id';

  static fields() {
    return {
      _id: this.string(''),
      name: this.string(''),
      token: this.attr(null),
      xSupplierId: this.string(''),
      unauthorized: this.boolean(true),
      disabled: this.boolean(false),
      rating: this.number(5),
      selected: this.boolean(false),
      wbSettingsToken: this.string(''),
      wbStatisticToken: this.string(''),
      wbAdToken: this.string(''),
      companyType: this.attr(null),
      cookie: this.string(''),
    };
  }

  get id() {
    return this._id;
  }

  declare _id: string;
  declare name: string;
  declare token?: any;
  declare xSupplierId: string;
  declare unauthorized: boolean;
  declare disabled: boolean;
  declare selected: boolean;
  declare rating: number;
  declare wbSettingsToken: string;
  declare wbStatisticToken: string;
  declare wbAdToken: string;
  declare companyType: MPType;
  declare cookie: string;

  static parse(user: Partial<MPSellers>): MPSellers {
    return new MPSellers({
      ...user,
    });
  }
}
