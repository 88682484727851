export default {
  main: {
    findByArticle: 'Поиск товаров по артикулу WB',
    currentMonth: 'Текущий месяц',
    previousMonth: 'Предыдущий месяц',
    currentWeek: 'Текущая неделя',
    previousWeek: 'Предыдущая неделя',
    chooseCategory: 'Выберите категорию товара',
    searchByArticle: 'Поиск продаж по этому артикулу',
    allSellers: 'Все продавцы',
    monday: 'ПН',
    tuesday: 'ВТ',
    wednesday: 'СР',
    thursday: 'ЧТ',
    friday: 'ПТ',
    saturday: 'СБ',
    sunday: 'ВС',
  },
  cards: {
    million: ' млн',
    thousands: ' тыс',
    units: ' шт.',
  },
  chart: {
    currency: ' ₽',
    selled: 'Продано',
    toPayout: 'К выплате',
    amount: 'Сумма',
    quantity: 'Количество',
    weeklyHeader: 'Накопительный итог текущей недели к предыдущей по дням ',
    mounthHeader: 'Накопительный итог текущего месяца к предыдущему по дням',
    thousands: ' тыс { val }',
    millions: ' млн { val }',
    billions: ' млрд { val }',
  },
  table: {
    total: 'Всего: ',
    exportXLS: 'Экспорт в XLS',
    settings: 'Настройки',
    name: 'Наименование товара',
    wb_article: 'Артикул WB',
    sellers_article: 'Артикул продавца',
    product_category: 'Категория товара',
    retail_price: 'Цена розничная, ₽',
    deal_price: 'Цена фактическая, ₽',
    discount_amount: 'Сумма скидки, ₽',
    amount_payout: 'Сумма к выплате, ₽',
    deal_trend: 'Тренд продаж',
    quantity: 'Количество, шт',
    summary_deal: 'Итого продано, ₽',
    summary_payout: 'Итого к выплате, ₽',
  },
  noAPI: {
    text: 'Для отображения статистики продаж добавьте в свойствах продавцов токен API для Аналитики',
    button: 'Настроить продавцов',
  },
};
