const employees = () => import('/src/views/hr/modules/employees/employees-list/employees-list.vue');
const employee = () => import('/src/views/hr/modules/employees/employees-detailed/employees-detailed.vue');
const jobPositions = () => import('/src/views/hr/modules/job-positions/job-positions-list/job-positions-list.vue');
const jobPosition = () => import('/src/views/hr/modules/job-positions/job-positions-detailed/job-positions-detailed.vue');
const applications = () => import('/src/views/hr/modules/applications/applications-list/applications-list.vue');
const application = () => import('/src/views/hr/modules/applications/applications-detailed/applications-detailed.vue');
const departments = () => import('/src/views/hr/modules/departments/departments-list/departments-list.vue');
const department = () => import('/src/views/hr/modules/departments/departments-detailed/departments-detailed.vue');

export default [
  {
    path: 'hr/employees',
    name: 'HREmployees',
    component: employees,
    meta: {
      title: 'employees',
    },
  },
  {
    path: 'hr/employees/:id',
    name: 'HREmployeesDetailed',
    component: employee,
    meta: {
      title: 'employee',
    },
  },
  {
    path: 'hr/jobPositions',
    name: 'HRJobPositions',
    component: jobPositions,
    meta: {
      title: 'jobPositions',
    },
  },
  {
    path: 'hr/jobPositions/:id',
    name: 'HRJobPositionsDetailed',
    component: jobPosition,
    meta: {
      title: 'jobPosition',
    },
  },
  {
    path: 'hr/applications',
    name: 'HRApplications',
    component: applications,
    meta: {
      title: 'applications',
    },
  },
  {
    path: 'hr/applications/:id',
    name: 'HRApplicationsDetailed',
    component: application,
    meta: {
      title: 'application',
    },
  },
  {
    path: 'hr/departments',
    name: 'HRDepartments',
    component: departments,
    meta: {
      title: 'departments',
    },
  },
  {
    path: 'hr/departments/:id',
    name: 'HRDepartmentsDetailed',
    component: department,
    meta: {
      title: 'department',
    },
  },
];
