import {WysiwygReplaceData, WysiwygTemplatesEnum, WysiwygTool} from '/@/components/wysiwyg/type';

import {logger} from '/@/utils/logger';

function createPattern(variable: string, flag = 'g') {
  const savePattern = variable.replace(/(\$|\{|\})/g, (char) => '\\' + char);

  return new RegExp(savePattern, flag);
}

const WysiwygTemplates = [
  {tpl: WysiwygTemplatesEnum.NAME, pattern: createPattern(WysiwygTemplatesEnum.NAME)},
  {tpl: WysiwygTemplatesEnum.VALUE, pattern: createPattern(WysiwygTemplatesEnum.VALUE)},
  {tpl: WysiwygTemplatesEnum.SELECTED, pattern: createPattern(WysiwygTemplatesEnum.SELECTED)},
];

function replaceToName(value: string, pattern: RegExp, tool: WysiwygTool, start: number, end: number): WysiwygReplaceData {
  if (!tool.name) {
    return {
      value,
      pos: end,
    };
  }
  const prev = value.substring(0, start);
  const after = value.substring(end);
  const insert = tool.template.replace(pattern, tool.name);

  return {
    value: prev + insert + after,
    pos: end + insert.length,
  };
}
function replaceToVALUE(value: string, pattern: RegExp, tool: WysiwygTool, start: number, end: number): WysiwygReplaceData {
  if (!tool.value) {
    return {
      value,
      pos: end,
    };
  }
  const prev = value.substring(0, start);
  const after = value.substring(end);
  const insert = tool.template.replace(pattern, tool.value);

  return {
    value: prev + insert + after,
    pos: end + insert.length,
  };
}
function replaceToSelected(value: string, pattern: RegExp, tool: WysiwygTool, start: number, end: number): WysiwygReplaceData {
  const prev = value.substring(0, start);
  const after = value.substring(end);
  const selected = value.substring(start, end);
  const insert = tool.template.replace(pattern, selected);  

  return {
    value: prev + insert + after,
    pos: end + insert.length,
  };
}

export function transformValue(control: HTMLTextAreaElement | HTMLInputElement, tool: WysiwygTool): WysiwygReplaceData {
  const start = control.selectionStart || 0;
  const end = control.selectionEnd || 0;
  const pattern = WysiwygTemplates.find((t) => tool.template.search(t.pattern) > 0);

  switch (pattern?.tpl) {
  case WysiwygTemplatesEnum.NAME: {
    return replaceToName(control.value, pattern.pattern, tool, start, end);
  }
  case WysiwygTemplatesEnum.VALUE: {
    return replaceToVALUE(control.value, pattern.pattern, tool, start, end);
  }
  case WysiwygTemplatesEnum.SELECTED: {
    return replaceToSelected(control.value, pattern.pattern, tool, start, end);
  }
  default: return {
    value: control.value,
    pos: end,
  };
  }
}
