export default {
  'Please enter the correct mobile number': 'Пожалуйста, введите корректный мобильный телефон',
  'Please enter the correct email': 'Пожалуйста, введите корректный адрес эл. почты',
  'Please enter the correct account': 'Аккаунт требует от 3 до 15 символов и содержит a-z A-Z 0-9 _',
  'Please enter the correct password': 'Пароль не соответствует требованиям безопасности',
  'Please enter the correct name': 'Пожалуйста, введите корректное имя',
  'Content cannot be empty': 'Поле не может быть пустым',
  'Floating point number': 'Число с плавающей точкой',
  required: 'Обязательно',
  symbolsQuantity: 'Введите от {min} до {max} знаков',
  'editor required': 'editor Required',
  //new added
  'Please enter name': 'Введите имя',
  'Please enter password': 'Введите пароль',
  'Please enter email': 'Введите адрес электронной почты',
  'Please choose profile': 'Выберите профиль прав доступа',
  'Please enter answer': 'Пожалуйста введите ответ',
  'Please enter longer name': 'Имя должно быть длиннее 4 символов',
  'Please delete prohibited symbol': "Пароль содержит недопустимые спецсимволы, разрешены только !{'@'}#$%^&*_",
  'Duplicate profile name': 'Такое название роли уже существует',
  'Please choose any rule': 'Выберите хотя бы одно правило',
  'Contains only whitespaces': 'Значение не должно состоять только из пробельных символов',
  bankAccount: 'Введите корректные данные банковской карты',
  inn: {
    integer: 'ИНН может состоять только из цифр',
    minMax: 'ИНН может состоять только из 10 или 12 цифр',
  },
  kpp: {
    integer: 'КПП может состоять только из цифр',
    minMax: 'КПП может состоять только из 9 цифр',
  },
  ogrn: {
    integer: 'ОГРН может состоять только из цифр',
    minMax: 'ОГРН может состоять только из 13 цифр',
  },
  zip: {
    integer: 'Почтовый индекс может состоять только из цифр',
    minMax: 'Почтовый индекс может состоять только из 6 цифр',
  },
  integer: 'Значение должно быть числом',
  website: 'Введите правильный URL адрес',
  name: 'Пожалуйста, используйте только буквы (лат./кир.), дефис или апостроф',
  unicValue: 'Необходимо указать уникальное значение',
  internalNotes: 'Примечание должно быть не более 2500 символов',
  'Please choose workflow': 'Пожалуйста, выберите стадию',
  departmentName: 'Используйте буквы или цифры в названии подразделения.',
  department: {
    unicValue: 'Подразделение с таким названием уже существует',
  },
  jobPositionName: 'Используйте буквы или цифры в названии должности.',
  jobPosition: {
    unicValue: 'Должность с таким названием уже существует',
  },
  customFields: {
    'Please delete prohibited symbol': 'Пожалуйста, используйте только буквы (лат./кир.) и цифры',
  },
  float: 'Значение поля должно иметь целочисленное или дробное значение',
  currency: 'Значение поля должно иметь целочисленное или дробное значение',
  employee: {
    sack: {
      date: 'Нельзя ввести дату увольнения раньше даты трудоустройства',
    },
  },
};
