export default {
  name: 'Название должности',
  expectedRecruitment: 'Количество вакансий',
  numberOfEmployees: 'Количество сотрудников',
  employees: 'Сотрудников',
  totalForecastedEmployees: 'Штатная численность',
  description: 'Описание вакансии',
  staffing: 'Укомплектованность',
  recruitmentStatus: 'Статус набора сотрудников',
  labels: {
    department: 'Подразделение',
    totalForecastedEmployees: 'Штатная численность',
    jobPositionsWorkflow: 'Статус набора сотрудников',
    expectedRecruitment: 'Вакансий',
    staffingLevel: 'Укомплектованность',
    numberOfEmployees: 'Сотрудников',
  },
  placeholders: {
    department: 'Выберите подразделение',
    name: 'Введите название должности',
    expectedRecruitment: 'Введите нужное количество',
    description: 'Введите описание вакансии',
    totalForecastedEmployees: 'Введите штатную численность',
    jobPositionsWorkflow: 'Выберите статус набора сотрудников',
  },
  buttons: {
    create: 'Создать должность',
  },
  modal: {
    create: {
      title: 'Создание новой должности',
      alert: 'При создании должности заполните основные сведения. Дополнительную информацию можно добавить после создания должности.',
    },
    title: {
      delete: 'Удаление должности',
      multipleDelete: 'Удаление должностей',
    },
    body: {
      delete: 'Удалить должность?',
      multipleDelete: 'Удалить выбранные должности?',
    },
  },
  filters: {
    workflowOptions: {
      'No Recruitment': 'Набор закрыт',
      'Recruitement in Progress': 'Набор открыт',
    },
  },
};
