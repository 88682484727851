import { createPinia, getActivePinia, Pinia, Store } from 'pinia';
import { createORM } from 'pinia-orm';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);
pinia.use(createORM());

interface ExtendedPinia extends Pinia {
  _s: Map<string, Store>;
}
export const $ResetPinia = () => {
  const pinia = getActivePinia() as ExtendedPinia;

  if (!pinia) {
    throw new Error('There is no stores');
  }

  const resetStores: Record<string, () => void> = {};

  pinia._s.forEach((store, name) => {
    resetStores[name] = () => store.$reset();
  });

  resetStores.all = () => pinia._s.forEach((store) => store.$reset());
  return resetStores;
};

export default pinia;
export * from './config';
export * from './adminInfo';
export * from './navigation-tabs';
export * from './siteConfig';
export * from './userInfo';
export * from './users';
export * from './profiles';
export * from './modules';
export * from './licenses';
export * from './customFields';
