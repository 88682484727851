import {createRouter, createWebHistory} from 'vue-router';
import multiguard from 'vue-router-multiguard';
import {permissionGuard, progressEndGuard, progressStartGuard, authGuard, superUserGuard, licensesGuard} from '/@/router/guards';

import routes from '/@/router/routes';

const router = createRouter({
  history: createWebHistory(),
  routes: routes,
});

router.beforeEach(multiguard([progressStartGuard, authGuard, permissionGuard, superUserGuard, licensesGuard]));
router.afterEach(progressEndGuard);
router.onError((error, to) => {
  if (error.message.includes('Failed to fetch dynamically imported module')) {
    window.location = to.fullPath;
  }
});

export default router;
