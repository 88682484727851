import {NavigationGuard, NavigationHookAfter} from 'vue-router';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';

import {loading} from '/@/utils/loading';
import {Local} from '/@/utils/storage';
import {navigationModules} from '/@/router/navigation-modules';
import {useUserInfo} from '/@/stores';
import LicenseService from '/@/api/licenses';
import {useLicensesInfo} from '/@/stores/licenses';
const {getAllLicenses} = LicenseService();

export const authGuard: NavigationGuard = async(to, from, next) => {
  if (to.matched.some(item => item.meta.authIsRequired)) {
    if (Local.has('authorized') && Local.get('authorized')) {
      if (to.name !== 'login') {
        return next();
      }
      return next({name: 'erp'});
    }
    return next({name: 'login'});
  }

  if (Local.has('authorized') && Local.get('authorized')) {
    if (to.name === 'login') {
      return next({name: 'erp'});
    }
  } else {
    return next();
  }
};

export const superUserGuard: NavigationGuard = async(to, from, next) => {
  if (to.matched.some(item => item.meta.onlySuperAdmin)) {
    const {getUserModules} = useUserInfo();
    if (getUserModules.superAdmin) {
      return next();
    }
    return next({name: 'erp'});
  }
  return next();
};

export const permissionGuard:NavigationGuard = async(to, from, next) => {
  if (to.matched.some(item => item.meta.requiresPermission)) {
    const module = navigationModules.find(module => {
      return (module.to?.name === to.name) || (module.to && to.name.toString().includes(module.to.name));
    });
    if (module) {
      const userPermissions = Local.get('userModules');
      const isPermitted = userPermissions.some(permission => permission.module === module._id && permission.access.read === true);
      if (isPermitted) {
        return next();
      }
      return next({name: 'erp'});
    }
  } else {
    return next();
  }
};

export const progressStartGuard: NavigationGuard = (to, from, next) => {
  NProgress.configure({showSpinner: false});
  NProgress.start();

  if (!window.existLoading) {
    loading.show();
    window.existLoading = true;
  }
  next();
};

export const progressEndGuard: NavigationHookAfter = () => {
  if (window.existLoading) {
    loading.hide();
  }
  NProgress.done();
};

export const licensesGuard: NavigationGuard = async(to, from, next) => {
  // Проверки to.name гарантируют, что guard не отрабатывает в разделах Licenses и Billing
  // Проверки Local authorized - гарантируют, что guad отрабатывает только для авторизованного пользователя
  if (to.name !== 'Licenses' && to.name !== 'Billing' && Local.has('authorized') && Local.get('authorized')) {
    const {setLicenses} = useLicensesInfo();
    const licensesData = await getAllLicenses();
    const isNoneActiveLicenses = Array.isArray(licensesData) && licensesData.some(item => !!item.invoice === true);

    setLicenses(licensesData);

    if (!isNoneActiveLicenses) {
      return next({name: 'Licenses'});
    }
  }
  return next();
};
