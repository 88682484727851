export default {
  title: 'Заполните поля и нажмите ',
  titleBold: 'Сгенерировать текст ответа',
  form: {
    productDesc: 'Введите наименование товара',
    customerName: 'Введите имя покупателя',
    text: 'Введите текст отзыва',
    rating: 'Укажите оценку в отзыве',
    responseText: 'Здесь будет текст ответа',
    btnText: 'Сгенерировать текст ответа',
  },
  validate: {
    desc: 'Необходимо указать наименование товара',
    name: 'Необходимо указать имя покупателя',
    text: 'Необходимо указать текст отзыва',
    rate: 'Укажите оценку товара, минимум - 1 звезда',
  },
  infoTg: {
    textInc: 'Подключите ',
    textLink: 'Телеграм-бот ',
    text: {
      str1: ' Sales Area, и больше не нужно будет вручную заполнять поля.',
      str2: 'Отзывы ваших магазинов на Wildberries будут приходить прямо в telegram, где вы сможете отвечать на них, генерируя ответы с помощью нейросети.',
      str3: 'Подробнее о Телеграм-боте смотрите',
      strLink: 'здесь.',
    },
    btnText: 'Попробовать Телеграм-бот бесплатно',
    attentionText: 'При увеличении нагрузки на бесплатный сервис генерации ответов количество доступных ответов в сутки может быть ограничено автоматически.',
  },
};
