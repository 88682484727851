const leads = () => import('/@/views/crm/modules/leads/leads-list.vue');
const leadDetailed = () => import('/@/views/crm/modules/leads/leads-detailed.vue');
const companies = () => import('/@/views/crm/modules/companies/companies-list/companies-list.vue');
const companiesDetailed = () => import('/@/views/crm/modules/companies/companies-detailed/companies-detailed.vue');
const contacts = () => import('/@/views/crm/modules/contacts/contacts-list/contacts-list.vue');
const contactsDetailed = () => import('/@/views/crm/modules/contacts/contacts-detail/contacts-detail.vue');
const opportunities = () => import('/@/views/crm/modules/opportunities/opportunities-list.vue');
const opportunitiesDetailed = () => import('/@/views/crm/modules/opportunities/opportunities-detailed.vue');
const tasks = () => import('/src/views/crm/modules/tasks/tasks-list/tasks-list.vue');
const tasksDetailed = () => import('/src/views/crm/modules/tasks/tasks-detailed/tasks-detailed.vue');

export default [
  {
    path: 'crm/leads',
    name: 'CrmLeads',
    component: leads,
    meta: {
      title: 'leads',
    },
  },
  {
    path: 'crm/leads/:leadId',
    name: 'CrmLeadsDetailed',
    component: leadDetailed,
    meta: {
      title: 'lead',
    },
  },
  {
    path: 'crm/companies',
    name: 'CrmCompanies',
    component: companies,
    meta: {
      title: 'companies',
    },
  },
  {
    path: 'crm/companies/:companyId',
    name: 'CrmCompaniesDetailed',
    component: companiesDetailed,
    meta: {
      title: 'company',
    },
  },
  {
    path: 'crm/contacts',
    name: 'CrmContacts',
    component: contacts,
    meta: {
      title: 'contacts',
    },
  },
  {
    path: 'crm/contacts/:id',
    name: 'CrmContactsDetailed',
    component: contactsDetailed,
    meta: {
      title: 'contact',
    },
  },
  {
    path: 'crm/opportunities',
    name: 'CrmOpportunities',
    component: opportunities,
    meta: {
      title: 'opportunities',
    },
  },
  {
    path: 'crm/opportunities/:opportunityId',
    name: 'CrmOpportunitiesDetailed',
    component: opportunitiesDetailed,
    meta: {
      title: 'opportunitie',
    },
  },
  {
    path: 'crm/tasks',
    name: 'CrmTasks',
    component: tasks,
    meta: {
      title: 'tasks',
    },
  },
  {
    path: 'crm/tasks/:id',
    name: 'CrmTasksDetailed',
    component: tasksDetailed,
    meta: {
      title: 'task',
    },
  },
];
