<template>
  <el-dialog
    v-model="dialogVisibleModel"
    :item-id="itemId"
    width="658px"
    @close="onClose"
  >
    <template #header>
      <span class="el-dialog__title">{{ dialogTitle }}</span>
      <el-steps
        :active="active"
        class="pt2"
        align-center
      >
        <template v-for="(tab, index) in currentTabs">
          <el-step :title="tab.title" />
        </template>
      </el-steps>
    </template>
    <div>
      <slot @mounted="onMounted" />
    </div>
    <template #footer>
      <el-row>
        <el-col
          :span="12"
          align="left"
        >
          <template v-if="itemId !== 'new'">
            <el-button
              type="danger"
              plain
              :disabled="!can('delete', `${itemName}`)"
              @click="() => onDelete(itemId)"
            >
              {{ $t('delete') }}
            </el-button>
          </template>
        </el-col>
        <el-col :span="12">
          <template v-if="active > 0">
            <el-button @click="() => back()">
              {{ $t('back') }}
            </el-button>
          </template>
          <template v-if="active < maxTabIndex">
            <el-button
              type="primary"
              @click="() => next()"
            >
              {{ $t('next') }}
            </el-button>
          </template>
          <template v-else>
            <template v-if="itemId == 'new'">
              <el-button
                type="primary"
                @click="() => onSubmit()"
              >
                {{ $t('create') }}
              </el-button>
            </template>
            <template v-else>
              <el-button
                type="primary"
                :disabled="!can('write', `${itemName}`)"
                @click="() => onSubmit()"
              >
                {{ $t('Save') }}
              </el-button>
            </template>
          </template>
          <el-button @click="() => onClose()">
            {{ $t('Cancel') }}
          </el-button>
        </el-col>
      </el-row>
    </template>
  </el-dialog>
</template>

<script lang="ts">
import {defineComponent, ref, provide, type PropType} from 'vue';
import {useAbility} from '@casl/vue';

export default defineComponent({
  name: 'TrpModalWizard',
  props: {
    dialogVisible: {
      required: true,
    },
    dialogTitle: {
      type: String,
      required: true,
    },
    itemId: {
      required: true,
      type: null as unknown as PropType<TNullable<string>>,
    },
    itemName: String,
    customTabs: {
      type: Array,
      default: () => [
        {
          title: 'Шаг 1',
        },
        {
          title: 'Шаг 2',
        },
        {
          title: 'Шаг 3',
        },
      ],
    },
  },

  setup() {
    const active = ref<number>(0);
    const maxTabIndex = ref<number>(0);
    provide('active', active);

    const {can} = useAbility();
    return {
      active,
      maxTabIndex,
      can,
    };
  },

  data() {
    return {
      formComponent: null,
    };
  },

  computed: {
    dialogVisibleModel: {
      set(value: any) {
        this.$emit('update:dialogVisible', value);
      },
      get() {
        return this.dialogVisible;
      },
    },
    currentTabs() {
      return this.customTabs as any[];
    },
  },

  watch: {
    customTabs(oldValue, newValue) {
      if (newValue !== oldValue) {
        this.maxTabIndex = this.customTabs.length - 1;
      }
    },
  },

  mounted() {
    this.setDefaultValues();
  },

  methods: {
    onSubmit() {
      if (this.formComponent) {
        (this.formComponent as any).onSubmit();
      }
    },
    onDelete(itemId: string | undefined) {
      this.$emit('delete', itemId);
    },
    onMounted(event: any) {
      this.formComponent = event;
    },
    onClose() {
      this.active = 0;
      this.$emit('close', false);
    },
    setDefaultValues() {
      this.maxTabIndex = this.customTabs.length - 1;
    },
    next() {
      if (this.formComponent) {
        (this.formComponent as any).validate();
      }
    },
    back() {
      this.active--;
    },
  },
});
</script>
