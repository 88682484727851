export enum ProcessState {
  UNKNOWN = 0,
  PROGRESS = 1,
  PENDING = 2,
  SUCCESS = 3,
  FAILED = 4,
  ERROR = 5,
}

export enum WorkerState {
  UNKNOWN = 0,
  STARTED = 1,
  PENDING = 2,
  STOPPED = 3,
  ERROR = 5,
}

export enum EntityState {
  UNKNOWN = 0,
  SUCCESS = 1,
  FAILED = 2,
  HIDDEN = 3,
  ERROR = 5,
}