export default {
  buttons: {
    create: 'Создать сотрудника',
    sack: 'Уволить сотрудника',
    addJobNote: 'Добавить запись',
    confirmSack: 'Уволить',
  },
  modal: {
    title: {
      create: 'Создание нового сотрудника',
      delete: 'Удаление сотрудника',
      addNote: 'Добавить новую запись',
      multipleDelete: 'Удаление сотрудника',
    },
    body: {
      create: {
        alert: 'При создании сотрудника заполните основные сведения. Дополнительную информацию и вложения можно добавить после создания сотрудника.',
      },
      delete: 'Удалить сотрудника?',
      multipleDelete: 'Удалить выбранных сотрудников?',
    },
    sack: {
      sackDate: 'Дата увольнения',
      radio: {
        voluntary: 'Увольнение по собственному желанию',
        agreement: 'Увольнение по соглашению сторон',
        other: 'Другая причина увольнения',
      },
      reason: 'Причина увольнения',
      placeholders: {
        reason: 'Укажите причину увольнения',
      },
    },
  },
  identNo: 'Номер ID',
  passportNo: 'Паспортные данные',
  bankAccountNo: 'Банковские реквизиты',
  otherId: 'Дополнительные сведения',
  labels: {
    jobPosition: 'Должность',
    department: 'Подразделение',
    personalEmail: 'Личный E-mail',
    mobile: 'Личный телефон',
    dateBirth: 'День рождения',
    email: 'E-mail',
    phone: 'Телефон',
  },
  placeholders: {
    identNo: 'Введите номер ID',
    passportNo: 'Введите паспортные данные',
    bankAccountNo: 'Введите банковские реквизиты',
    otherId: 'Введите дополнительные сведения',
    searchByName: 'Поиск сотрудника по имени и фамилии',
    jobPosition: 'Выберите должность',
    department: 'Выберите подразделение',
    dateBirth: 'Укажите день рождения',
  },
  title: {
    passportData: 'Паспортные данные, банковские реквизиты',
    address: 'Адрес проживания',
    status: 'Статус',
  },
};
