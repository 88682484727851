const questions = () => import('/@/views/autoanswers/modules/questions/questions.vue');
const templates = () => import('/@/views/autoanswers/modules/templates/templates.vue');
const categories = () => import('/@/views/autoanswers/modules/categories/questions-categories.vue');

export default [
  {
    path: 'autoanswers/questions',
    name: 'AutoanswersQuestions',
    component: questions,
    meta: {
      title: 'questions',
    },
  },
  {
    path: 'autoanswers/templates',
    name: 'AutoanswersTemplates',
    component: templates,
    meta: {
      title: 'questionTemplates',
    },
  },
  {
    path: 'autoanswers/categories',
    name: 'AutoanswersCategories',
    component: categories,
    meta: {
      title: 'questionsCategories',
    },
  },
];
