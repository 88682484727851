export default {
  addedField: 'Добавлено поле',
  changedField: 'Изменено поле',
  before: 'до',
  historyStartTime: 'Выберите дату от',
  historyEndTime: 'Выберите дату до',
  noData: 'В указанный промежуток времени ничего не происходило',
  fields: {
    assignedTo: 'Ответственный',
    creationDate: 'Дата создания',
    requestType: 'Обращение',
    category: 'Категория',
    company: {
      simple: 'Компания',
      name: {
        first: 'Название компании',
      },
    },
    website: 'Веб-сайт',
    country: 'Страна',
    firstName: 'Имя',
    lastName: 'Фамилия',
    dateOfBirth: 'Дата рождения',
    isOpportunitie: 'Лид или сделка',
    description: 'Описание',
    dealSource: 'Источник сделки',
    email: 'Email',
    amount: 'Планируемая сумма заказа',
    currency: 'Валюта',
    dateBirth: 'Дата рождения',
    closeDate: 'Планируемая дата завершения',
    name: 'Название',
    workflow: 'Статус',
    priority: 'Приоритет',
    salesPerson: 'Ответственный',
    source: 'Источник',
    contactName: {
      simple: 'Контакт',
      face: 'Контактное лицо',
      first: 'Имя',
      last: 'Фамилия',
    },
    phones: {
      phone: 'Телефон',
    },
    tags: 'Теги',
    changeDate: 'Дата изменения',
    duration: 'Длительность',
    social: {
    },
    jobPosition: 'Должность',
    address: {
      country: 'Страна',
      zip: 'Почтовый индекс',
      state: 'Регион',
      city: 'Город',
      street: 'Улица',
    },
    dueDate: 'Срок выполнения',
    person: 'Персона',
  },
  tabs: {
    history: 'История',
    tasks: 'Задачи',
    attachments: 'Файлы',
    subtasks: 'Подзадачи',
  },
  labels: {
    task: {
      name: 'Название задачи',
      workflow: 'Стадия',
    },
  },
};
