<template>
  <el-dialog
    v-model="dialogVisibleModel"
    :item-id="itemId"
    :title="dialogTitle"
    :width="width"
    @close="onClose"
  >
    <slot @mounted="onMounted" />
    <template #footer>
      <el-row>
        <el-col
          align="left"
          :span="12"
        >
          <template v-if="itemId !== 'new'">
            <el-button
              type="danger"
              :disabled="!can('delete', `${itemName}`)"
              plain
              @click="() => onDelete(itemId)"
            >
              {{ $t('delete') }}
            </el-button>
          </template>
        </el-col>
        <el-col :span="12">
          <template v-if="itemId == 'new'">
            <el-button
              type="primary"
              @click="() => onSubmit()"
            >
              {{ $t('create') }}
            </el-button>
          </template>
          <template v-else>
            <el-button
              :disabled="!can('write', `${itemName}`)"
              type="primary"
              @click="() => onSubmit()"
            >
              {{ $t('Save') }}
            </el-button>
          </template>
          <el-button @click="() => onClose()">
            {{ $t('Cancel') }}
          </el-button>
        </el-col>
      </el-row>
    </template>
  </el-dialog>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import {useAbility} from '@casl/vue';
import {logger} from '/@/utils/logger';

export default defineComponent({
  name: 'TrpModalForm',
  props: {
    templateId: String,
    dialogVisible: {
      required: true,
    },
    dialogTitle: {
      type: String,
      required: true,
    },
    itemId: String,
    width: {
      type: String,
      default: '500px',
    },
    itemName: String,
  },
  emits: ['update:dialogVisible', 'submit', 'close', 'delete'],
  setup() {
    const {can} = useAbility();

    return {
      can,
    };
  },
  data() {
    return {
      formComponent: null,
    };
  },
  computed: {
    dialogVisibleModel: {
      set(value: any) {
        this.$emit('update:dialogVisible', value);
      },
      get() {
        return this.dialogVisible;
      },
    },
  },
  mounted() {
  },
  methods: {
    onSubmit() {
      if (this.formComponent) {
        (this.formComponent as any).onSubmit();
      }
    },
    onMounted(event: any) {
      this.formComponent = event;
    },
    onClose() {
      this.$emit('close', false);
    },
    onDelete(itemId: string | undefined) {
      this.$emit('delete', itemId);
    },
  },
});
</script>
