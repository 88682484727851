import type {ILicense} from '/@/types/licenses';

import createAxios from '/@/utils/axios';
import entityEndpoints from '/@/api/licenses/entity.endpoints';

export default function LicenseService() {
  //DOCUMENTATION https://dev.terpo.ru/docs/static/index.html#/license/get_api_license_list
  function _createUrl(endpoint: string) {
    return '/api/license/' + endpoint;
  }
  function getAllLicenses(): ApiPromise<ILicense[]> {
    return createAxios({
      url: _createUrl(entityEndpoints.getAllLicenses),
    });
  }
  function activateLicense(licenseId: string, tariffId: string) {
    return createAxios({
      url: _createUrl(entityEndpoints.activateLicense + licenseId),
      method: 'patch',
      params: {
        tariffId,
      },
    });
  }
  function deactivateLicense(licenseId: string) {
    return createAxios({
      url: _createUrl(entityEndpoints.deactivateLicense + licenseId),
      method: 'patch',
    });
  }
  function suspendLicense(licenseId: string) {
    return createAxios({
      url: _createUrl(entityEndpoints.suspendLicense + licenseId),
      method: 'patch',
    });
  }
  function resumeLicense(licenseId: string) {
    return createAxios({
      url: _createUrl(entityEndpoints.resumeLicense + licenseId),
      method: 'patch',
    });
  }
  function enableAutoProlongation(licenseId: string) {
    return createAxios({
      url: _createUrl(entityEndpoints.enableAutoProlongation + licenseId),
      method: 'patch',
    });
  }
  function disableAutoProlongation(licenseId: string) {
    return createAxios({
      url: _createUrl(entityEndpoints.disableAutoProlongation + licenseId),
      method: 'patch',
    });
  }
  function getBillingHistory(params: Record<string, number | string>) {
    return createAxios({
      url: _createUrl(entityEndpoints.getBillingHistory),
      params,
    });
  }
  function getBalance() {
    return createAxios({
      url: _createUrl(entityEndpoints.getBalance),
    });
  }
  function proceedToYookassa(params: Record<string, string| number>) {
    return createAxios({
      url: _createUrl(entityEndpoints.proceedToYookassa),
      params,
    });
  }
  return {
    getAllLicenses,
    activateLicense,
    deactivateLicense,
    suspendLicense,
    resumeLicense,
    enableAutoProlongation,
    disableAutoProlongation,
    getBillingHistory,
    getBalance,
    proceedToYookassa,
  };
}
