export default {
  menu: 'Главное меню',
  help: 'Справка',
  darkMode: 'Изменить тему',
  account: 'Аккаунт',
  search: 'Поиск',
  refresh: 'Обновить',
  edit: 'Изменить',
  close: 'Закрыть',
  fullscreen: 'На полный экран',
  filter: 'Фильтр',
  switchOff: 'Отключить',
  switchOn: 'Включить',
  pageBack: 'Предыдущая страница',
  pageForward: 'Следующая страница',
  answer: 'Ответить',
  choose: 'Выбрать',
  chooseAll: 'Выбрать все',
  copyLink: 'Скопировать ссылку',
  addTag: 'Добавить тег',
  table: 'Таблица',
  kanban: 'Канбан доска',
  hierarchy: 'Иерархия',
  openContact: 'Открыть контакт',
  crm: {
    previous: {
      default: 'Предыдущий',
      leads: 'Предыдущий лид',
      opportunities: 'Предыдущая сделка',
      companies: 'Предыдущая компания',
      contacts: 'Предыдущий контакт',
      employees: 'Предыдущий сотрудник',
      applications: 'Предыдущий кандидат',
      tasks: 'Предыдущая задача',
      jobPositions: 'Предыдущая должность',
    },
    next: {
      default: 'Следующий',
      leads: 'Следующий лид',
      opportunities: 'Следующая сделка',
      companies: 'Следующая компания',
      contacts: 'Следующая контакт',
      employees: 'Следующий сотрудник',
      applications: 'Следующий кандидат',
      tasks: 'Следующая задача',
      jobPositions: 'Следующая должность',
    },
  },
  setFollowers: 'Настроить наблюдателей',
  searchByArticle: 'Поиск отзывов по этому артикулу',
};
