export default {
  searchInput: 'Поиск вопросов по артикулу WB',
  countUnanswered: 'Всего: ',
  seller: 'Продавец',
  category: 'Категории вопросов',
  auto: 'Автоответ:',
  autoTag: 'Автоответ',
  manual: 'Ручной ответ:',
  manualTag: 'Ручной ответ',
  countLines: 'Всего { count } строк',
  answerType: 'Тип ответа',
  answer: 'Ответ',
  status: 'Статус',
  noAnswer: 'Без ответа',
  withAnswer: 'С ответом',
  new: 'Новый',
  newQuestions: 'Новые:',
  archiveWb: 'Архивные WB',
  exportExcel: 'Экспорт в XLS',
  createNew: 'Создать новый',
  processedBySA: 'Обработаны Sales Area',
  processedByWB: 'Обработаны Wildberries',
  startAutoAnswer: 'Запустить автоответ',
  clear: 'Очистить фильтр',
  processed: 'Обработанные',
  processedBy: 'Кем обработаны',
  templateQualities: 'Свойства шаблона',
  variant: 'Вариант ответа',
  allCategories: 'Все категории',
  allStatuses: 'Все статусы',
  allSellers: 'Все продавцы',
  lastUpdateText: 'Данные от WB получены: ',
  defineCategory: 'Определить категорию',
  exportXLSBtn: 'Экспорт в XLS',
  createTemplate: 'Создать новый шаблон',
  addCategoryBtn: 'Добавить категорию',
  answerTextLengthValidator: 'Длина ответа превышает 1000 символов. Пожалуйста, сократите текст',
  noAPI: {
    text: 'Для отображения списка вопросов добавьте в свойствах продавцов токен API для Автобота.',
    button: 'Настроить продавцов',
  },
  table: {
    productName: 'Наименование товара',
    productCode: 'Артикул WB',
    question: 'Вопрос',
    questionDate: 'Дата',
    category: 'Категория',
    status: 'Статус',
    answerBtn: 'Ответить',
    noCategory: 'Не определена',
    sellerId: 'Артикул продавца',
  },
  dialog: {
    answer: {
      title: 'Ответ на вопрос',
      productTitle: 'Наименование товара',
      questionLabel: 'Вопрос',
      categoryLabel: 'Категория вопроса',
      answerLabel: 'Ответ',
      answerBtn: 'Ответить',
      updateAnswerBtn: 'Обновить ответ',
      cancelBtn: 'Отмена',
      answerByAIBtn: 'Сгенерировать ответ (ИИ)',
      templateSelect: 'Выбрать ответ из шаблонов',
      enterAnswer: 'Введите текст ответа',
      sellerId: 'Артикул продавца',
      productCode: 'Артикул WB',
      exampleBtn: 'Добавить в примеры для ИИ',
    },
  },
  templates: {
    buttons: {
      addTemplate: 'Создать шаблон',
      addVariant: 'Добавить вариант ответа',
      addQuestionExample: 'Добавить пример вопроса',
    },
    placeholders: {
      enterBrands: 'Введите одно или несколько названий брендов',
      enterKeywords: 'Введите одно или несколько ключевых слов или фраз',
      tplName: 'Введите название шаблона',
      enterAnswer: 'Введите текст ответа',
      filterCompany: 'Поиск шаблона по продавцу',
      filterAnswer: 'Поиск шаблона по назначению ответа',
      filterAnswerType: 'Поиск шаблона по типу ответа',
      filterName: 'Поиск шаблона по названию',
      chooseCompany: 'Выберите продавца',
      chooseCategory: 'Выберите одну или несколько категорий товара',
      chooseQuestionCategory: 'Выберите одну или несколько категорий вопроса',
      noCategory: 'Нет доступных для выбора категорий',
      autoTemplates: 'Автоматические',
      manualTemplates: 'Ручные',
    },
    modal: {
      title: {
        create: 'Создание шаблона для вопроса',
        edit: 'Редактирование шаблона для вопроса',
      },
    },
    labels: {
      tplQuestions: 'Шаблоны для вопросов',
      tplType: 'Тип ответа',
      tplName: 'Название шаблона',
      tplText: 'Варианты ответа',
      rate: 'Оценки',
      auto: 'Автоответ',
      manualAnswer: 'Ручной ответ',
      brand: 'Бренд',
      useBrand: 'Применять к вопросам с брендом',
      useCategory: 'Применять к вопросам с категорией товара',
      recommended: 'Товары для рекомендаций (введите номера через запятую 1,2,3,5 или с указанием диапазона 1-3,5)',
      useKeywords: 'Применять к вопросам с ключевыми словами',
      useStopKeywords: 'Не применять к вопросам со стоп-словами',
      useNmIds: 'Применять к вопросам с артикулами',
      excludePictures: 'Не применять к вопросам с изображениями',
      keywords: 'Ключевые слова',
      photos: 'Изображения',
      name: 'Наименование шаблона',
      manual: 'Ручной',
      autoType: 'Автоматический',
      textTemplates: 'Варианты ответа',
      textTemplate: 'Вариант ответа',
      template: 'Вариант',
      variables: 'Используйте в тексте:',
      tplState: 'Состояние шаблона',
      state: 'Состояние',
      on: 'Включен',
      justAnswer: 'Ответ покупателю',
      useSeller: 'Предлагать для вопросов по продавцу',
      chooseCategory: 'Предлагать для категорий вопросов',
      excludeWhat: 'Исключения (только ручной ответ)',
      conditionsUses: 'Условия применения',
      answerPurpose: 'Назначение ответа',
      answerVariants: 'Варианты ответов (варианты ответов будут использованы в случайном порядке)',
      answerType: 'Тип ответа',
      status: 'Статус',
      seller: 'Продавец',
      allAnswerTypes: 'Все типы шаблонов',
      allAnswerPurpose: 'Все назначения ответов',
      category: 'Категория вопроса',
      answerKind: 'Способ ответа',
      AIAnswer: 'Нейросеть',
      templatesAnswer: 'Подготовленные варианты',
    },
    dialog: {
      delete: {
        messageOne: 'Удалить шаблон?',
        messageMany: 'Удалить выбранные шаблоны?',
        type: 'Удаление шаблона',
      },
    },
    WYSIWYG: {
      prodname: 'Наименование товара',
      brandname: 'Наименование бренда',
      sellername: 'Наименование продавца',
      recommend: 'Рекомендации',
      customername: 'Имя покупателя',
    },
    hints: {
      'You can pass that': 'Вы можете добавить в текст шаблона следующее переменные',
      'Duplicate keyword another': 'Дублирование ключевого слова в другом шаблоне',
      'Duplicate keyword this': 'Дублирование ключевого слова в этом шаблоне',
      'Duplicate nmId this': 'Дублирование артикула в этом шаблоне',
      'Duplicate brand this': 'Дублирование бренда в этом шаблоне',
    },
  },
  tooltips: {
    brands: {
      title: 'Совет по выбору брендов',
      description:
        'Оставьте поле пустым, если ответ универсальный и подходит для товаров всех брендов текущего продавца. Если нет, перечислите нужные бренды.',
    },
    keywords: {
      title: 'Совет по выбору ключевых слов',
      description:
        'Ключевые слова - наиболее часто используемые слова и словосочетания в вопросах ваших покупателей. Например, «не подошло», «брак», «долгая доставка» и т.п. Обратите внимание, если указать «не подошло», то ответ сработает на вопросы с «не подошло», но пропустит вопросы, содержащие «подошло» или «не подходит».',
    },
    codes: {
      title: 'Совет по выбору артикулов',
      description: 'Оставьте поле пустым, если ответ универсальный и подходит для всех товаров. Если нет, перечислите нужные артикулы.',
    },
  },
  answerStatuses: {
    auto: 'Автоответ',
    autoWithAI: 'Автоответ ИИ',
    manual: 'Ручной ответ',
    manualWidthAI: 'Ручной ответ ИИ',
    external: 'Внешний ответ',
    wait: 'В обработке',
  },
  categories: {
    addCategoryBtn: 'Создать новую категорию',
    newCategoryPlaceholder: 'Название категории',
    sendCategoryBtn: 'Создать',
    cancelBtn: 'Отмена',
  },
  noUserName: 'Имя покупателя скрыто',
  questionsExamples: {
    dialog: {
      title: 'Удаление примера вопроса',
      removeQuestion: 'Удалить пример?',
      removeQuestions: 'Удалить выбранные примеры?',
      header: 'Добавление примера вопроса для ИИ',
      headerEdit: 'Редактирование примера вопроса для ИИ',
      alert:
        'Пример для обучения ИИ должен содержать только суть вопроса без приветствия и подписи. Избегайте ошибок при написании. Для одного примера желательно добавлять не более трех категорий, к которым этот вопрос имеет отношение.',
      inputLabel: 'Пример вопроса',
      inputPlaceholder: 'Введите пример вопроса',
      selectLabel: 'Категория вопроса',
      selectPlaceholder: 'Выберите категорию вопроса',
      addBtn: 'Добавить',
      saveBtn: 'Сохранить',
    },
    table: {
      questionExample: 'Пример вопроса',
      categoriesExample: 'Принадлежность к категориям',
    },
  },
};
