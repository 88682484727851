import { defineStore } from 'pinia';
import createAxios from '/@/utils/axios';

import type { ICustomField, IEditedCustomField } from '/@/types/crm';

interface IState {
  fields: Map<string, ICustomField>;
}

export const useCustomFields = defineStore('customFields', {
  state: (): IState => {
    return {
      fields: new Map<string, ICustomField>(),
    };
  },
  actions: {
    setCustomFields(payload: Record<string, ICustomField>): void {
      Object.entries(payload).forEach(([key, customField]) => {
        if (Object.prototype.hasOwnProperty.call(customField, 'value')) {
          this.fields.set(key, customField);
        } else {
          this.fields.set(key, { ...customField, value: '' });
        }
      });
    },
    removeCustomField(fieldKey: string): void {
      if (this.fields.has(fieldKey)) {
        this.fields.delete(fieldKey);
      } else {
        throw new Error('Невозможно удалить поле. Поле с указанным ключём не существует');
      }
    },
    fetchCustomFields(entityType: string = 'leads', entityId?: string): ApiPromise {
      return createAxios({
        url: `api/customFields/${entityType}`,
        params: {
          entityId,
        },
        method: 'get',
      }).then((res: any) => {
        const { response, status } = res as { response: Record<string, ICustomField>; status: number };

        if (status === 200) {
          if (Object.keys(response).length > 0) this.setCustomFields(response);
          return response;
        }
      });
    },
    saveCustomFields(entityType: string = 'leads', fields: Record<string, IEditedCustomField>): ApiPromise {
      const formatedFields = Object.entries(fields).reduce((acc, [key, props]) => {
        const formatedProps = { ...props };
        delete formatedProps.new;
        // на всякий пожарный удаляем value, что-бы случайно не перезаатереть value (но таких кейсов быть не должно)
        delete formatedProps.value;
        return { ...acc, [key]: formatedProps };
      }, {});

      if (Object.keys(formatedFields).length > 0) {
        return createAxios({
          url: `api/customFields/${entityType}`,
          data: {
            ...formatedFields,
          },
          method: 'post',
        });
      }
      throw new Error('Отсутствуют кастомные поля для сохранения, либо их значения сохраняются не корректно');
    },
    updateCustomFieldValue(entityType: string = 'leads', entityId: string, fieldKey: string, fieldValue: string): ApiPromise | void {
      if (entityId && fieldKey) {
        return createAxios({
          url: `api/customFields/${entityType}`,
          data: {
            entityId,
            key: fieldKey,
            value: fieldValue.trim(),
          },
          method: 'patch',
        });
      }
    },
    deleteCustomField(entityType: string, key: string): ApiPromise | void {
      if (typeof entityType === 'string' && typeof key === 'string' && entityType.length > 0 && key.length > 0) {
        return createAxios({
          url: `api/customFields/${entityType}`,
          params: {
            key,
          },
          method: 'delete',
        });
      }
      throw new Error('Не верно заданы параметры для удаления кастомного поля');
    },
    updateCustomFieldOrder(fieldList: any, path: string, entityType: string = 'leads'): ApiPromise | void {
      const reorderedCustomFields = fieldList.filter((item) => item.type === 'custom-field').map((item) => item.fieldOptions.name);

      const fields = this.getCustomFieldsForPath(path);
      let fieldsToUpdate = {};

      for (const [key, value] of Object.entries(fields)) {
        const index = reorderedCustomFields.indexOf(key);
        const updatedProps = { ...value };
        delete updatedProps.value;

        if (index > -1) {
          fieldsToUpdate = {
            ...fieldsToUpdate,
            [key]: { ...updatedProps, uiPath: `${path}/${index + 1}` },
          };
        }
      }

      return createAxios({
        url: `api/customFields/${entityType}`,
        data: {
          ...fieldsToUpdate,
        },
        method: 'post',
      });
    },
    resetCustomFields() {
      this.fields = new Map<string, ICustomField>();
    },
  },
  getters: {
    getCustomField: (state) => (key: string) => {
      if (state.fields.has(key)) {
        return state.fields.get(key);
      }
    },
    getCustomFieldsForPath: (state) => {
      return (uiPath: string) => {
        let fields = {};
        if (state.fields.size === 0) return {};

        for (const [key, value] of state.fields.entries()) {
          // uiPath example 'main/0', where `main` is tab name, and `0` is field order
          if (value.uiPath.split('/')[0].includes(uiPath)) fields = { ...fields, [key]: value };
        }
        return fields;
      };
    },
    isCustomFieldExist: (state) => (key: string) => state.fields.has(key),
    getMaxListOrder: (state) => {
      let maxOrder = 0;

      for (const field of state.fields.values()) {
        if (field.uiPath.includes('/')) {
          const fieldOrder = Number(field.uiPath.split('/')[1]) ?? 0;

          if (fieldOrder > maxOrder) maxOrder = fieldOrder;
        }
      }

      return maxOrder;
    },
  },
});
