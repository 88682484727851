import modules from '/@/router/modules';

const AuthView = () => import('/@/views/auth/auth-view.vue');
const Licenses = () => import('/@/views/licenses/licenses-view.vue');
const Billing = () => import('/@/views/licenses/billing-view.vue');
const BalanceChecker = () => import('/@/views/licenses/balance-checker-view.vue');

export default [
  {
    path: '/:pathMatch(.*)*',
    redirect: {name: 'notFound'},
  },
  {
    path: '/login',
    name: 'login',
    component: AuthView,
    meta: {
      title: 'login',
    },
  },
  {
    path: '/404',
    name: 'notFound',
    component: () => import('@views/common/error/404.vue'),
    meta: {
      title: 'notFound',
    },
  },
  {
    path: '/401',
    name: 'noPower',
    component: () => import('@views/common/error/401.vue'),
    meta: {
      title: 'noPower',
    },
  },
  {
    path: '/',
    name: 'root',
    redirect: {name: 'erp'},
  },
  // Licenses
  {
    path: '/balance-checker',
    name: 'balance-checker',
    component: BalanceChecker,
    meta: {
      title: 'balance-checker',
      authIsRequired: true,
      onlySuperAdmin: true,
    },
  },
  {
    path: '/erp',
    name: 'erp',
    component: () => import('/@/layouts/main-layout.vue'),
    meta: {
      title: 'erp',
      authIsRequired: true,
    },
    children: [
      ...modules,
      {
        path: 'licenses',
        name: 'Licenses',
        component: Licenses,
        meta: {
          title: 'licenses',
          authIsRequired: true,
          onlySuperAdmin: true,
        },
      },
      {
        path: 'billing',
        name: 'Billing',
        component: Billing,
        meta: {
          title: 'billing',
          authIsRequired: true,
          onlySuperAdmin: true,
        },
      },
      {
        path: 'help',
        name: 'help',
        component: () => import('@views/help/help-view.vue'),
        meta: {
          title: 'help',
          authIsRequired: true,
        },
      },
    ],
  },
];
