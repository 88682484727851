import {ErpModules} from './modules';
import {Model} from 'pinia-orm';

import type IPermission from '../types/permission.model';

export class Profiles extends Model {
  static entity = '[Profiles]';
  static primaryKey: string | string[] = '_id';

  static fields() {
    return {
      _id: this.attr(''),
      profileName: this.string(''),
      profileDescription: this.string(''),
      profileAccess: this.attr([]), 
      isTemplated: this.boolean(false),
      //deprecated
      superAdmin: this.boolean(false), 
    };
  }

  declare _id: any;
  declare profileName: string;
  declare profileAccess: IPermission[];
  declare profileDescription: string;
  declare isTemplated: boolean;
  //deprecated
  declare superAdmin: boolean;

  static parse(profile: Partial<Profiles>): Profiles {
    return new Profiles({
      ...profile,
    });
  }
}

export interface PermissionsAccess { 
  del: boolean, 
  editWrite: boolean,
  read: boolean,
}

export class Permissions extends Model {
  static entity = '[Permissions]';
  static primaryKey: string | string[] = '_id';

  static fields() {
    return {
      _id: this.string(''),
      access: this.attr(null),
      module: this.attr(''),
    };
  }

  declare _id: string;
  declare access: PermissionsAccess;
  declare module: ErpModules['id'];
  declare profiles: Profiles[];
}


//TODO: remove cross-table, store current user in [Users], permissions in [Permissions]
export class ProfilesPermissions extends Model {
  static entity = '_ProfilesPermissions';
  static primaryKey = ['permissions_id', 'profiles_id'];
  static fields() {
    return {
      permissions_id: this.attr(null),
      profiles_id: this.attr(null),
    };
  }

  static parseFromProfile(profile: Partial<Profiles>): {
    profilesPermissions: ProfilesPermissions[],
    permissions: Permissions[],
    profiles: Profiles[],
    } {
    const permissions: Permissions[] = [];
    
    const profiles = [new Profiles({
      profileName: profile.profileName,
      _id: `${profile._id}`,
    })];


    // TODO: refactor this to get current user permissions
    
    // for (const permission in profile.profileAccess || {}) {
    //   if (permission.module._id) {
    //     permissions.push(new Permissions({
    //       ...permission,
    //       _id: `${permission.module._id}`
    //     }))
    //   }
    // }

    const profilesPermissions = permissions.map((permission) => new ProfilesPermissions({
      profiles_id: `${profile._id}`,
      permissions_id: `${permission.module._id}`,
    })
    );
    
    return {
      profilesPermissions,
      permissions,
      profiles,
    };
  }
}
