import {Profiles} from '/@/stores/profiles';

import {Model} from 'pinia-orm';
import {useDateFormat} from '@vueuse/core';


export class Users extends Model {
  static entity = '[Users]';
  static primaryKey: string | string[] = '_id';

  get id() {
    return this._id;
  }

  static fields() {
    return {
      _id: this.string(''),
      imageSrc: this.string(''),
      login: this.string(''),
      email: this.string(''),
      contactName: this.attr({
        first: this.string(''),
        last: this.string(''),
      }),
      lastAccess: this.string(''),
      credentials: this.attr(''),
      profile_id: this.attr(''),
      profile: this.belongsTo(Profiles, 'profile_id'),
      mobilePhone: this.string(''),
      current: this.boolean(false),
      company: this.string(null),
      superAdmin: this.boolean(false),
    };
  }


  static mutators() {
    return {
      lastAccess: {
        get: (value: any) => value ? useDateFormat(value, 'DD.MM.YYYY HH:mm:ss').value : '',
      },

    };
  }

  static parse(user: Partial<Users>, current = false): Users {
    let profileData: Partial<Users> = {
      profile: undefined,
      profile_id: undefined,
    };

    if (user.profile) {
      profileData = {
        profile_id: `a${user.profile._id}`,
      };
    }

    return new Users({
      ...current ? {current: true} : {},
      ...user,
      ...profileData,
      superAdmin: user.profile?.superAdmin,
    });
  }

  declare _id: string;
  declare imageSrc: string;
  declare login: string;
  declare email: string;
  declare contactName: any;
  declare lastAccess: string;
  declare credentials: any;
  declare profile_id: any;
  declare profile: Profiles | undefined;
  declare mobilePhone: string;
  declare current: boolean;
  declare company: string;
  declare superAdmin: boolean;
}
