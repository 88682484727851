import { logger } from '/@/utils/logger';
import { ElLoading, ElNotification } from 'element-plus';
import { i18n } from '/@/lang';
import axios, { type AxiosInstance, type AxiosRequestConfig } from 'axios';

import type IOptions from '/@/types/axios/IOptions';
import type { LoadingOptions } from 'element-plus/es/components/loading/src/types';

import httpErrorStatusHandle from '/@/utils/axios/error-handler';

interface ILoadingInstance {
  target: any;
  count: number;
}

export default function useInterceptors(Axios: AxiosInstance, axiosConfig: AxiosRequestConfig, options: IOptions, loading: LoadingOptions) {
  const pendingMap = new Map();
  const loadingInstance: ILoadingInstance = {
    target: null,
    count: 0,
  };

  options = Object.assign(
    {
      cancelDuplicateRequest: true,
      loading: false,
      reductDataFormat: true,
      showErrorMessage: true,
      showCodeMessage: true,
      showSuccessMessage: false,
      anotherToken: '',
    },
    options,
  );

  Axios.interceptors.request.use(
    (config) => {
      logger.request(`${axiosConfig.url} :: `, { request: axiosConfig });
      removePending(config);
      options.cancelDuplicateRequest && addPending(config);
      if (options.loading) {
        loadingInstance.count++;
        if (loadingInstance.count === 1) {
          loadingInstance.target = ElLoading.service(loading);
        }
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    },
  );

  Axios.interceptors.response.use(
    (response) => {
      logger.success(`${axiosConfig.url} :: `, { response });
      removePending(response.config);
      options.loading && closeLoading(options);
      if (response.config.responseType == 'json' && options.showSuccessMessage && response.data && response.data) {
        ElNotification({
          message: response.data.msg ? response.data.msg : i18n.global.t('axios.Operation successful'),
          type: 'success',
        });
      }

      return options.reductDataFormat ? response.data : response;
    },
    (error) => {
      error.config && removePending(error.config);
      options.loading && closeLoading(options);
      options.showErrorMessage && httpErrorStatusHandle(error);
      return Promise.reject(error);
    },
  );

  function closeLoading(options: IOptions) {
    if (options.loading && loadingInstance.count > 0) loadingInstance.count--;
    if (loadingInstance.count === 0) {
      loadingInstance.target.close();
      loadingInstance.target = null;
    }
  }

  function addPending(config: AxiosRequestConfig) {
    const pendingKey = getPendingKey(config);
    config.cancelToken =
      config.cancelToken ||
      new axios.CancelToken((cancel) => {
        if (!pendingMap.has(pendingKey)) {
          pendingMap.set(pendingKey, cancel);
        }
      });
  }

  function removePending(config: AxiosRequestConfig) {
    const pendingKey = getPendingKey(config);
    if (pendingMap.has(pendingKey)) {
      const cancelToken = pendingMap.get(pendingKey);
      cancelToken(pendingKey);
      pendingMap.delete(pendingKey);
    }
  }

  function getPendingKey(config: AxiosRequestConfig) {
    let { data } = config;
    const { url, method, params, headers } = config;
    if (typeof data === 'string') data = JSON.parse(data);
    return [
      url,
      method,
      headers && headers.batoken ? headers.batoken : '',
      headers && headers['ba-user-token'] ? headers['ba-user-token'] : '',
      JSON.stringify(params),
      JSON.stringify(data),
    ].join('&');
  }
}
