import type { RouteRecordRaw } from 'vue-router';

import crm from './crm';
import hr from './hr';
import settings from './settings';
import ai from './ai';
import autoanswers from './autoanswers';
//Analytics
const analytics = () => import('@views/analytics/modules/sales-stat/sales-stat.vue');
//Autobot
const reviews = () => import('/@/views/autobot/modules/reviews/reviews.vue');
const templates = () => import('/@/views/autobot/modules/templates.vue');
const statistics = () => import('/@/views/autobot/modules/statistics/statistics-page.vue');
const ozonReviews = () => import('/@/views/autobot/modules/reviews/ozon-reviews.vue');
//Settings
const autobot = () => import('/@/views/autobot/modules/settings.vue');
const users = () => import('/@/views/usersList/modules/usersList.vue');
const roles = () => import('/@/views/accessList/modules/accessList.vue');
const notifications = () => import('/@/views/notifications/modules/notifications.vue');

//Logistics
const supplyPlanning = () => import('@views/logistics/modules/supply-planning.vue');
const wildberriesWarehouses = () => import('@views/logistics/modules/wildberries-warehouses.vue');

const modules: RouteRecordRaw[] = [
  ...crm,
  ...hr,
  ...settings,
  ...ai,
  ...autoanswers,
  //Analytics
  {
    path: 'analytics/sales',
    name: 'AnalyticsSales',
    component: analytics,
    meta: {
      title: 'salesStat',
    },
  },
  //Logistics
  {
    path: 'logistics/warehouses',
    name: 'WildberriesWarehouses',
    component: wildberriesWarehouses,
    meta: {
      title: 'warehouses',
    },
  },
  {
    path: 'logistics/supply',
    name: 'SupplyPlanning',
    component: supplyPlanning,
    meta: {
      title: 'supply',
    },
  },
  //Autobot
  {
    path: 'autobot/reviews',
    name: 'AutobotReviews',
    component: reviews,
    meta: {
      title: 'reviewsWB',
    },
  },
  {
    path: 'autobot/templates',
    name: 'AutobotTemplates',
    component: templates,
  },
  {
    path: 'autobot/statistics',
    name: 'AutobotStatistics',
    component: statistics,
  },
  {
    path: 'autobot/ozon-reviews',
    name: 'OzonReviews',
    component: ozonReviews,
    meta: {
      title: 'ozonReviews',
    },
  },
  // Settings
  {
    path: 'settings/autobot',
    name: 'SettingsAutobot',
    component: autobot,
  },
  {
    path: 'settings/users',
    name: 'SettingsUsers',
    component: users,
  },
  {
    path: 'settings/roles',
    name: 'SettingsRoles',
    component: roles,
  },
  {
    path: 'settings/notifications',
    name: 'SettingsNotifications',
    component: notifications,
  },
];
modules.forEach((module) => {
  if (!module.meta) {
    module.meta = {};
  }
  module.meta['authIsRequired'] = true;
  module.meta['requiresPermission'] = true;
});
export default modules;
