import { reactive, toRaw } from 'vue';
import { defineStore } from 'pinia';

import type { LocationQuery, RouteParams, RouteLocationNormalized, RouteMeta, RouteRecordRaw } from 'vue-router';

import { Local } from '/@/utils/storage';
import { prepareModulesForNavigation } from '/@/utils/load-module';
import pagesTitle from '/@/lang/ru/pages-title';

interface IState {
  activeIndex: number;
  activeRoute: RouteLocationNormalized | null;
  tabsView: {
    path: string;
    hash: string;
    meta: RouteMeta;
    params: RouteParams;
    query: LocationQuery;
  }[];
  tabFullScreen: boolean;
  tabsViewRoutes: RouteRecordRaw[];
  authNode: Map<string, string[]>;
}

const LOCAL_STORAGE_NAME = 'navigation-tabs';
export const useNavigationTabs = defineStore('useNavigationTabs', () => {
  const navigationTabs = reactive<IState>({
    activeIndex: 0,
    activeRoute: null,
    tabsView: Local.get(LOCAL_STORAGE_NAME) || [],
    tabFullScreen: false,
    tabsViewRoutes: [],
    authNode: new Map(),
  });

  const addNavigationTab = (route: RouteLocationNormalized, uniqueName?: string) => {
    if (route.name !== 'erp') {
      for (const key in navigationTabs.tabsView) {
        if (navigationTabs.tabsView[key].path === route.path) {
          navigationTabs.tabsView[key].params = route.params ?? navigationTabs.tabsView[key].params;
          navigationTabs.tabsView[key].query = route.query ?? navigationTabs.tabsView[key].query;
          return;
        }
      }
      setMetaTitle(route, uniqueName);
      navigationTabs.tabsView.push({
        meta: { ...route.meta },
        path: route.path,
        params: route.params,
        hash: route.hash,
        query: { ...route.query },
      });
    }
    Local.set(LOCAL_STORAGE_NAME, toRaw(navigationTabs.tabsView));
  };

  const closeNavigationTab = (route: RouteLocationNormalized) => {
    navigationTabs.tabsView.map((view, index) => {
      if (view.path === route.path) {
        navigationTabs.tabsView.splice(index, 1);
      }
    });
    Local.set(LOCAL_STORAGE_NAME, navigationTabs.tabsView);
  };

  const closeDeletedNavTabs = (tabs: string[]) => {
    tabs.map((item) => {
      const openTabIndex = navigationTabs.tabsView.findIndex((navigationTab) => navigationTab.params.id === item);
      if (openTabIndex > -1) {
        navigationTabs.tabsView.splice(openTabIndex, 1);
      }
    });
    Local.set(LOCAL_STORAGE_NAME, navigationTabs.tabsView);
  };

  const closeNavigationTabs = (retainMenu: RouteLocationNormalized | false = false) => {
    if (retainMenu) {
      navigationTabs.tabsView = [retainMenu];
    } else {
      navigationTabs.tabsView = [];
    }
    Local.set(LOCAL_STORAGE_NAME, navigationTabs.tabsView);
  };

  const setActiveRoute = (route: RouteLocationNormalized): void => {
    const currentRouteIndex = navigationTabs.tabsView.findIndex((item: Partial<RouteLocationNormalized>) => item.path === route.path);
    if (currentRouteIndex !== -1) {
      navigationTabs.activeRoute = route;
      navigationTabs.activeIndex = currentRouteIndex;
    }
  };
  const setTabsViewRoutes = (data: RouteRecordRaw[]): void => {
    navigationTabs.tabsViewRoutes = encodeRoutesURI(data);
  };

  const setAuthNode = (key: string, data: string[]) => {
    navigationTabs.authNode.set(key, data);
  };

  const setFullScreen = (fullScreen: boolean): void => {
    navigationTabs.tabFullScreen = fullScreen;
  };

  return {
    navigationTabs,
    addNavigationTab,
    closeNavigationTab,
    closeNavigationTabs,
    setActiveRoute,
    setTabsViewRoutes,
    setAuthNode,
    setFullScreen,
    closeDeletedNavTabs,
  };
});

function encodeRoutesURI(data: RouteRecordRaw[]) {
  data.forEach((item) => {
    if (item.meta?.type == 'iframe') {
      item.path = '/admin/iframe/' + encodeURIComponent(item.path);
    }
    if (item.children && item.children.length) {
      item.children = encodeRoutesURI(item.children);
    }
  });
  return data;
}

function setMetaTitle(route: RouteLocationNormalized, uniqueName?: string) {
  const { preparedNavigation } = prepareModulesForNavigation();
  const matchedRoute = preparedNavigation.find((module) => module?.to && route.name === module.to.name);
  route.meta.title = matchedRoute?.mname || pagesTitle[route.meta.title as keyof typeof pagesTitle];
  if (uniqueName) {
    route.meta.title += ` ${uniqueName}`;
  }
}
