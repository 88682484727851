import {computed, reactive} from 'vue';
import {defineStore} from 'pinia';

import type {IUser} from '/src/types/crm/user.model';

import {Local} from '/@/utils/storage';

interface IUserInfo {
  user: TNullable<IUser>,
  savedFilters: TNullable<unknown>
}

export const useUserInfo = defineStore('userInfo', () => {
  const userInfo = reactive<IUserInfo>({
    user: Local.has('userInfo') ? Local.get('userInfo') : null,
    savedFilters: null,
  });
  const setUserInfo = (data: IUser) => {
    userInfo.user = data;
  };
  const setUserFilters = (filters: unknown) => {
    userInfo.savedFilters = filters;
  };
  const clear = () => {
    userInfo.user = null;
    userInfo.savedFilters = null;
  };
  const getUserInfo = computed(() => userInfo.user);
  const getUserModules = computed(() => userInfo.user?.profile);
  return {
    setUserInfo, setUserFilters, clear, getUserInfo, getUserModules,
  };
});
